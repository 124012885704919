import React from 'react'
import style from './orderdetails.scss'

import { withTranslation } from "react-i18next";

import {inject, observer } from 'mobx-react'

import { Collapse, Toast, Button, Dialog, TextArea, Steps, Ellipsis, Popup, Popover } from 'antd-mobile'
import { RightOutline, DownOutline, UpOutline, StarFill } from 'antd-mobile-icons'
import moment from 'moment'

import { generateEstimate } from './../util/paramsHelper'

import images from './../util/images'

import LoadingMask from './../component/utils/loadingMask';
import IPathMap from './../component/utils/iPathMap'

import  { appId, oauthType, customerServiceTelephone } from './../util/config'

import loger from './../util/loger'

import webService from './../util/webService'
import { dge, base64ToString, payType, ipay } from './../util/utils'
import { getPosition4Element } from './../util/geo'
import { serviceStateToCn } from './../util/paramsHelper'
import MessageBox from './../component/callcar/messageBox'
import { ForgeRouter } from './../util/utils'

import gc from '../util/globalConfiguration'

@inject('globalStore', 'mainStore', 'estimateStore')
@observer
class OrderNewDetails extends React.Component {
  state = {
    showLoding: true, // 地图 loading
    orderInfo: {}, // 订单信息
    polyline: [], // 路线点
    payState: 2, // 支付状态
    locationGeo: {
      lat: '',
      lon: '',
      name: ''
    },
    isDisabled: false,
    feedbackLabels: [], // 评价 labal
    evaluateValue: '', // 评价内容
    auditData: [], // 审批数据
    reasonVal: '', // 用车理由

    driverKey: '1', // 1
    evaluateKey: '', // 2
    detailsKey: null, // 3
    cancelKey: null, // 4
    approvalKey: null, // 5
    activeDriverKey: '1',
    activeEvaluateKey: '',
    activeDetailsKey: '',
    activeCancelKey: '',
    activeApprovalKey: '',

    isShowDriver: true,
    isShowDetails: true,
    isShowEvaluate: true,
    isShowaCancel: true,
    isShowApprovalHistory: true,

    orderId: '', // 订单 id
    historyArray: [], // 历史审批
    lastArray: [], // 当前审批
    showHistory: true, // 控制历史审批按钮显隐
    voucherMoney: '', // 优惠金额
    complaintInfo: [], // 投诉进度
    payStatus: '',
    score: null, // 评分
    tpyes: [], // 评价标签
    feedback: null, // 评价内容
    showAbnormal: false, // 是否展示异常提示
    jumpAbnormal: false, // 是否可跳转异常订单页
    billMain: {}, // 预付费相关
    isPrepayment: false, // 判断是否为大额预付
    isRefund: null, // 预付费判断是否退款
    individualNeedPay: null,
    refundMoney: null,
    realScore: null,
    isOtherEvaluate: false,
    isEvaluateDisabled: true,
    remainingCnt: 0, // 用车事由次数
    feedbackType: 1,
    isActiveComplaint: false, // 是否产生过异常主动投诉
    isAutoComplaint: false,
    payDetail: {
      amountLimitList: []
    },
    showCarFeeDetail: false,
    showDestFeedback: false
  }

  globalStore = this.props.globalStore
  mainStore = this.props.mainStore
  estimateStore = this.props.estimateStore
  t = this.props.t

  timerSettling = null;
  
  scoreList = [
    {
      score: 5,
      images: images.superior_grey,
      label: this.t('orderdetails.k042') // 特别满意
    },
    {
      score: 3,
      images: images.medium_grey,
      label: this.t('orderdetails.k043') // 感觉一般
    },
    {
      score: 1,
      images: images.inferior_grey,
      label: this.t('orderdetails.k044') // 非常糟糕
    }
  ]

  iPathMapRef = undefined


  // 真实路径
  get isPathReal() {
    return (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_real_path)
  }

  messageBoxRef = undefined;

  // 真实路径动画
  get isPathRealAnimation() {
    return this.globalStore.userInfo.permissions && (this.globalStore.userInfo.permissions.orderdetails_real_path && this.globalStore.userInfo.permissions.orderdetails_real_path_animation)
  }

  // 判断如果真实位置有则显示真实位置终点名称没有则显示下单终点名称
  get destLocationName() {
    if (this.state.locationGeo.lat && this.state.locationGeo.lon) {
      return this.state.locationGeo.name
    } 
    return this.state.orderInfo.destLocationName
  }

  // 支付状态
  get payInfo() {
    if (this.state.orderInfo.isPay === false) {
      return {
        payName: this.t('orderdetails.k045'), // 待支付
        amount: this.state.orderInfo.personalPayableAmount,
        isPay: false
      }
    }
    return {
      payName: this.t('orderdetails.k046'), // 已支付
      amount: this.state.orderInfo.totalAmount,
      isPay: true
    }
  }

  // 获取美团结算状态
  get paySettling() {
    if (this.state.orderInfo.settling === 1 && gc.process.isMeituan) {
      return true
    }
    return false
  }

  // 获取订单评分
  get scoreInfo() {
    if (this.state.score === 5) {
      return {
        scoreLabel: this.t('orderdetails.k042'), // 特别满意
        icon: images.superior_blue,
        score: 5
      }
    } else if (this.state.score === 3) {
      return {
        scoreLabel: this.t('orderdetails.k043'), // 感觉一般
        icon: images.medium_blue,
        score: 3
      }
    } else if ([2, 1].includes(this.state.score)) {
      return {
        scoreLabel: this.t('orderdetails.k044'), // 非常糟糕
        icon: images.inferior_blue,
        score: 1
      }
    }
    return {
      scoreLabel:'',
      score: 0,
      icon: ''
    }
  }

  // 获取表情选中所有标签
  get loadFeedbackLabels() {
    if (this.scoreInfo.score === 5) {
      return this.state.feedbackLabels && this.state.feedbackLabels[0] && this.state.feedbackLabels[0].children
    } else if (this.scoreInfo.score === 3) {
      return this.state.feedbackLabels && this.state.feedbackLabels[1] && this.state.feedbackLabels[1].children
    } else if (this.scoreInfo.score === 1) {
      return this.state.feedbackLabels && this.state.feedbackLabels[2] && this.state.feedbackLabels[2].children
    } else {
      return []
    }
  }

  // 判断显示评价
  get showOrderEvaluate() {
    if (this.state.orderInfo.serviceType === 20 && this.state.orderInfo.state === 6) {
      return true
    }
    if (this.state.orderInfo.state === 6 && this.state.orderInfo.serviceType !== 20) {
      return true
    }
    if (this.state.orderInfo.state === 5 && gc.process.isMeituan) {
      return true
    }
    return false;
  }

  get complaintList() {
    if (this.state.tpyes) {
      return this.state.tpyes
    }
    return []
  }

  // 是否可提交评价
  get isEvaluateValue() {
    if (this.scoreInfo && this.state.isEvaluateDisabled) {
      return true
    }
    return false
  }
  
  // 用车场景展示隐藏
  get isSceneNameCn() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_hide_scene ? false : true
  }

  // 企业支付金额
  get isCompanyBearAmount() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_company_bear_amount ? false : true
  }

  // 个人支付金额
  get isUserBearAmount() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_user_bear_amount ? false : true
  }

  // 是否可修改用车事由
  get showUseCarReason() {
    return this.state.isDisabled;
  }

  // 用车事由权限
  get isChangeReason() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_change_reason
  }

  // 是否显示企业审批记录
  get isShowApproval() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_approval) {
      return true
    }
    return false
  }

  // 获取审批进度
  get approvalProgressCount () {
    let count = 0;
    if (this.state.historyArray && this.state.historyArray > 0) {
      count++
    }
    count += this.state.lastArray.filter((item) => item.totalNumber === item.approvedNumber).length
    return count
  }

  // 评价投诉进度条
  get progressCount() {
    if (this.state.complaintInfo?.state === 0) {
      return 0
    } else if (this.state.complaintInfo?.state === 1) {
      return 1
    } else {
      return 2
    }
  }

  // 是否显示投诉进度
  get isShowProgress() {
    return this.state.isActiveComplaint
  }

  // 是否显示投诉中心入口
  get showComplaintCenter() {
    if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_complaint) {
      return true
    }
    return false
  }

  // 只评分未评价内容
  get onlyScore() {
    return !this.state.feedback && !this.state.tpyes 
  }
  
  // 判断是否有历史记录
  get isHistoryArray() {
    if ((this.state.lastArray && this.state.lastArray.length > 0) || (this.state.historyArray && this.state.historyArray > 0)) {
      return true
    }
    return false
  }
  
  // 判断预付费
  get isShowPrepaid() {
    if (this.state.isPrepayment) {
      if (this.state.billMain && (this.state.billMain.individualPayState === 2 || this.state.billMain.individualPayState ===3)) {
        if (this.state.isRefund && !this.state.individualNeedPay) {
          return true
        }
      }
      if (this.state.billMain && this.state.billMain.individualPayState === 1) {
        return true
      }
      if ((!this.state.isRefund || this.state.refundMoney === 0) && !this.state.individualNeedPay) {
        return true
      }
      if (!this.state.billMain) {
        return true
      }
    }
    return false
  }

  // 判断用户评价是否选择其他
  get otherEvaluateChecked() {
    let arr = this.loadFeedbackLabels.filter(item => item.show)
    return arr
  }

  // 企业已支付
  get companyPaidAmount() {
    if (gc.process.isMeituan) {
      return this.state.orderInfo?.companyBearAmount ?? 0
    }
    return this.numSub(this.state.orderInfo?.totalAmount, this.add(this.state.orderInfo?.personalPayableAmount ?? 0, this.state.orderInfo?.personalPaidAmount ?? 0))
  }

  // 个人已支付
  get personalPaidAmount() {
    if (gc.process.isMeituan) {
      return this.state.orderInfo?.userBearAmount ?? 0
    }
    return this.state.orderInfo.personalPaidAmount
  }

  // 隐藏费用明细
  get isExpenseDetails() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_hide_expense_details ? false : true
  }

  // 隐藏取消订单客服中心入口
  get isCancelCustomerService() {
    return this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.orderdetails_cancel_customer_service ? false : true
  }

  // 获取语言展示元素宽度
  get languageMaxWidth() {
    return this.globalStore?.userInfo?.language === 2 ? true : false
  }

  // 美团是否展示费用说明弹窗
  get isShowCarFeeDetail() {
    if (this.state.payDetail?.needShowControlDetail === 1 && gc.process.isMeituan) {
      return true
    }
    return false
  }
  
  get expensesColor() {
    if (gc.process.isMeituan) {
      return '#455160'
    } else {
      return '#165DC4'
    }
  }

  get getStarColor() {
    if (gc.process.isMeituan) {
      return '#FF6A00'
    } else {
      return '#F5A623'
    }
  }
  

  /**
   *
   *初始化行程
   */
  renderMap() {
    return(
      <div className={style.map}>
        {/* 地图 */}
        <IPathMap t={this.props.t} ref={(dom) => { this.iPathMapRef = dom ? dom.getMapRef() : undefined }} className={style.amapContainer}></IPathMap>
      </div>
    )
  }

  // 上报位置
  renderInvestigationPosition() {
    return(
      <div className={style.investigation}>
        <div className={style.box}>
          <img alt="" src={images.investigation} />
          <span className={style.message}>
            {this.t('orderdetails.k094')}
          </span>
        </div>
        <div className={style.confirm_btn}>
          <div className={style.no} onClick={() => {
            this.uploadLocationFeedback(false)
          }}>{this.t('orderdetails.k095')}</div>
          <div className={style.yes} onClick={() => {
            this.uploadLocationFeedback(true)
          }}>{this.t('orderdetails.k096')}</div>
        </div>
      </div>
    )
  }

  /**
   *
   *订单状态相关展示
   */
  renderOrderState() {
    return(
      <div className={style.box_details}>
        <MessageBox ref={(dom) => { this.messageBoxRef = dom; }} history={this.props.history}></MessageBox>
        <div className={style.order_detalis}>
          <div className={style.details} id='details'>
             {/* 位置上报 */}
            {this.state.showDestFeedback ? this.renderInvestigationPosition() : ''}
            {/* 司机支付相关 */}
            {[5, 6].includes(this.state.orderInfo.state) || (this.state.orderInfo.state === 7 && this.state.orderInfo.totalAmount > 0) ? this.renderCellDriver() : ''}
            {/* 订单评价相关 */}
            {this.showOrderEvaluate ? this.renderCellEvaluate() : ''}
            {/* 投诉相关 */}
            {this.isShowProgress ? this.renderCellComplaint() : ''}
            {/* 行程取消 */}
            {this.state.orderInfo.state === 7 && this.state.orderInfo.totalAmount === 0 ? this.renderCellCancel() : ''}
            {/* 订单详情内容 */}
            {this.renderCellDetails()}
            {/* 订单审批记录 */}
            {this.isShowApproval && this.isHistoryArray ? this.renderProcessRecords() : ''}
          </div>
        </div>
      </div>
    )
  }

  /**
   *
   * 订单详情-司机支付相关
   */
  renderCellDriver() {
    return(
      <Collapse activeKey={this.state.activeDriverKey}>
        <Collapse.Panel onClick={() => {
          this.setState({
            driverKey: '1',
            activeDriverKey: '1',
            activeEvaluateKey: '',
            activeDetailsKey: '',
            activeCancelKey: '',
            activeApprovalKey: '',
            approvalKey: null,
            evaluateKey: null, // 2
            detailsKey: null, // 3
            cancelKey: null, // 4
            showHistory: true,
            isShowDriver: false
          })
          if (this.state.driverKey === '1' && this.state.activeDriverKey === '1' ) {
            this.setState({
              driverKey: null,
              activeDriverKey: '',
              isShowDriver: !this.state.isShowDriver,
            })
          }
        }} key={this.state.driverKey} title={
          [5, 6, 7].includes(this.state.orderInfo.state) ? 
          <div>
            <div className={style.head}>
              {
                this.state.driverKey !== null && this.state.orderInfo.state === 7 ? <div className={style.cancel_state}>
                  {
                    (() => {
                      if (this.state.orderInfo.state === 7) {
                        return this.t('orderdetails.k080') // 行程已取消
                      }
                    })()
                  }
                  {
                    (() => {
                      if (this.state.orderInfo.state === 7) {
                        if (this.state.orderInfo.personalPayableAmount > 0) {
                          return this.t('orderdetails.k081') // 请支付取消费
                        } else if(this.state.orderInfo.totalAmount > 0) {
                          return this.t('orderdetails.k082') // 产生取消费
                        }
                      }
                    })()
                  }
                </div> : <></>
              }
              
              <div className={style.head_top}>
                <div>
                  {/* <span className={style.title}>{this.t('orderdetails.k084')}</span>&nbsp; */}
                  <span className={style.pay_amount}>{this.state.orderInfo.totalAmount}</span>&nbsp;
                  {/* k047 元 */}
                  <span className={style.unit}>{this.t('orderdetails.k047')}</span>
                  {
                    this.payInfo.isPay ? <img className={style.pay_succeed} alt="" src={images.paySucceed} /> : <></>
                  }
                  {
                    gc.process.isMeituan && !this.payInfo.isPay ? <Popover
                      content={this.t('orderdetails.k090')}
                      trigger='click'
                      getContainer={() => document.getElementsByClassName('_1GYOIcePvYVu_Cw_10nqBL')[0]}
                      placement='bottom-start'
                    >
                      <img className={style.question_circle} alt="" src={images.questionCircle} />
                    </Popover> : <></>
                  }
                  
                  {/* k048；已优惠、 k047：元*/}
                  {
                    this.state.voucherMoney !== 0 && this.state.orderInfo.personalPayableAmount === 0 ?
                    <span className={style.discounts}>{this.t('orderdetails.k048')}{this.state.voucherMoney * -1}{this.t('orderdetails.k047')}</span> : <></>
                  }
                </div>
                  {/* k049：费用明细*/}
                  <div>
                  {
                    this.state.driverKey !== null && this.isExpenseDetails ? 
                    <span className={style.expenses} onClick={(e) => {
                      e.stopPropagation()
                      this.props.history.push(`/expenseDetails/${this.state.orderId}`)
                    }}>{this.t('orderdetails.k049')}<RightOutline fontSize={12} color={this.expensesColor}/></span> : <></>
                  }
                  {
                    this.state.driverKey === null ? <img className={style.deployment_img} alt='' src={images.deployment}/> : <></>
                  }
                  </div>
              </div>
              <div className={style.amount_display}>
                {/* 美团个人支付金额 */}
                {
                  (() => {
                    if (gc.process.isMeituan && this.state.payDetail?.personPayAmount > 0 && this.state.driverKey !== null) {
                      return (
                        // k088 需自费
                        <div className={style.self_pay}>{this.t('orderdetails.k088')}<span>{this.state.payDetail?.personPayAmount}&nbsp;{this.t('orderdetails.k001')}</span></div>
                      )
                    } else {
                      if (!gc.process.isMeituan && this.state.orderInfo.personalPayableAmount > 0 && this.state.driverKey !== null) {
                        return(
                          <div>
                            {/* 个人待支付 */}
                            <span className={style.paid}>{this.t('orderdetails.k083')} <span>{this.state.orderInfo.personalPayableAmount}</span> {this.t('orderdetails.k047')}</span>
                          </div>
                        )
                      }
                    }
                  })()
                }
                {
                this.state.voucherMoney !== 0 && this.state.orderInfo.personalPayableAmount > 0 && this.state.driverKey !== null ?
                <span className={style.show_discounts}>{this.t('orderdetails.k048')}{this.state.voucherMoney * -1}{this.t('orderdetails.k047')}</span> : <></>
                }
              </div>
              {
                this.state.driverKey !== null ? 
                <div className={style.box_cell}>
                  {
                    this.isShowPrepaid ? <div className={style.prepaid} onClick={() => {
                      this.props.history.push(`/prepaidProgress/${this.state.orderId}`)
                    }}><span>{this.t('orderdetails.k050')}</span><RightOutline fontSize={12} color={this.expensesColor}/></div> : <></> // k050：预付费详情
                  }
                  {
                    this.state.showAbnormal ? <span className={style.exceed} onClick={(e)=>{
                      e.stopPropagation()
                      if (this.state.jumpAbnormal && this.state.showAbnormal) {
                        this.props.history.push(`/orderAbnormal/${this.state.orderId}`)
                      } else {
                        Dialog.alert({
                          title:this.t('orderdetails.k037'),
                          confirmText: this.t('common.k003'),
                          getContainer: () => document.getElementsByClassName('_1GYOIcePvYVu_Cw_10nqBL')[0],
                          content: (
                            <>
                            {
                              this.state.orderInfo.abnormalRules.map((item,idx)=>{
                                return <div key={idx}>{idx+=1}、{item}</div>
                              })
                            }
                            {
                              this.state.orderInfo.abnormalTipFlag ? <div className={style.tip_message}>{this.state.orderInfo.tip}</div> : ''
                            }
                            </>
                          ),
          
                        })
                      }
                    }} // 本次行程超出标准，请确认
                    >{this.t('orderdetails.k085')} <RightOutline fontSize={12} color={'#f56161'}/> </span> : <></>
                  }
                </div> : <></>
              }
            </div>

            
            
          </div> : <></>
        }>
          {/* 司机信息 */}
          {this.renderOrderDriver()}
        </Collapse.Panel>
      </Collapse>
    )
  }

  /**
   * 订单详情-评价相关
   */
  renderCellEvaluate() {
    return(
      <Collapse activeKey={this.state.activeEvaluateKey}>
        <Collapse.Panel onClick={() => {
          if (this.onlyScore) {
            return
          }
          if (this.scoreInfo.score) {
            this.setState({
              evaluateKey: '2', // 2
              activeEvaluateKey: '2',
              activeDriverKey: '',
              activeDetailsKey: '',
              activeCancelKey: '',
              activeApprovalKey: '',
              approvalKey: null,
              driverKey: null, // 1
              detailsKey: null, // 3
              cancelKey: null, // 4
              showHistory: true,
              isShowEvaluate: false
            })
          }
          if (this.state.evaluateKey === '2' && this.state.activeEvaluateKey === '2') {
            this.setState({
              evaluateKey: null,
              activeEvaluateKey: '',
              isShowEvaluate: !this.state.isShowEvaluate
            })
          }
        }} key={this.state.evaluateKey} title={
          <div className={style.evaluate}>
            <div className={style.name}>
              <div>
                {/* k051：评价本次行程 */}
                <span>{this.t('orderdetails.k051')}</span>
                {/* k052：匿名 */}
                <span>{this.t('orderdetails.k052')}</span>
              </div>
              {/* 评分展示 */}
              {
                this.scoreInfo.scoreLabel ? <div>
                  <span>{this.scoreInfo.scoreLabel}</span>
                  <img className={style.score_img} alt='' src={this.scoreInfo.icon}/>
                  <img className={style.deployment_img} alt='' src={images.deployment}/>
                </div> : <></>
              }
            </div>
            {
              (this.complaintList.length === 0 && !this.state.feedback) && !this.onlyScore ? 
              <div className={style.reaction}>
                {
                  this.scoreList.map(item => {
                    return <div key={item.score} onClick={(e) => {
                      // e.stopPropagation()
                      this.state.feedbackLabels.forEach(item => {
                        item.children.forEach(val => {
                          val.checked = false
                          val.show = false
                        })
                      })
                      this.setState({
                        score: item.score
                      }, () => {
                        if (this.scoreInfo.score) {
                          this.setState({
                            evaluateKey: '2', // 2
                            activeEvaluateKey: '2',
                            activeDriverKey: '',
                            activeDetailsKey: '',
                            activeCancelKey: '',
                            driverKey: null, // 1
                            detailsKey: null, // 3
                            cancelKey: null, // 4
                            showHistory: true
                          })
                        }
                      })
                    }}>
                    <img alt='' src={this.scoreInfo.score === item.score ? this.scoreInfo.icon : item.images}/>
                    <span className={this.scoreInfo.score === item.score ? style.bule_name : ''}>{item.label}</span>
                  </div>
                  })
                }
              </div> : <></>
            }
            {
              this.complaintList.length === 0 && !this.state.feedback && this.state.realScore >= 1 ? this.renderComplaintEntrance() : ''
            }
          </div>
        }>
          <div className={style.appraise}>
          {this.renderEvaluationContent()}
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  }

  /**
   *
   * 订单详情-投诉进度
   */
  renderCellComplaint() {
    const { Step } = Steps
    return(
      <div className={style.complaint_details}>
        <div className={style.schedule} onClick={() => {
          this.props.history.push(`/complaintsDetails/${this.state.orderId}`)
        }}>
          {/* 投诉进度 */}
          <span>{this.t('orderdetails.k053')}</span>
          <RightOutline fontSize={16}/>
        </div>
        <Steps current={this.progressCount} direction={'horizontal'} style={{
          '--title-font-size': '17px',
          '--description-font-size': '15px',
          '--indicator-margin-right': '12px',
          '--icon-size': '22px',
        }}>
          <Step
            title={this.t('orderdetails.k054')} // k054：提交投诉
            icon={<img alt='' src={this.progressCount >= 0 ? images.blueChecked : images.greyChecked}/>}
          />
          <Step
            title={this.t('orderdetails.k055')} // k055：正在处理
            icon={<img alt='' src={this.progressCount >= 1 ? images.blueChecked : images.greyChecked}/>}
          />
          <Step
            title={this.t('orderdetails.k056')} // k056：处理完成
            icon={<img alt='' src={this.progressCount >= 2 ? images.blueChecked : images.greyChecked}/>}
          />
        </Steps>
      </div>
    )
  }


  /**
   * 订单详情-详情
   */
  renderCellDetails() {
    return(
      <Collapse activeKey={this.state.activeDetailsKey}>
        <Collapse.Panel onClick={() => {
          this.setState({
            detailsKey: '3',
            activeDetailsKey: '3',
            activeDriverKey: '',
            activeEvaluateKey: '',
            activeCancelKey: '',
            activeApprovalKey: '',
            approvalKey: null,
            driverKey: null, // 1
            evaluateKey: null, // 2
            cancelKey: null, // 4
            showHistory: true,
            isShowDetails: false
          })
          if (this.state.detailsKey === '3' && this.state.activeDetailsKey === '3' ) {
            this.setState({
              detailsKey: null,
              activeDetailsKey: '',
              isShowDetails: !this.state.isShowDetails,
            })
          }
        }} key={this.state.detailsKey} title={
          <div className={style.details_title}>
            {/* k057：订单详情 */}
            {this.t('orderdetails.k057')}
            {/* <DownOutline fontSize={12}/> */}
            <img className={style.deployment_img} alt='' src={images.deployment}/>
          </div>
        }>
          <div className={style.details_content}>
            {/* 订单编号 */}
            <p>
            {this.t('orderdetails.k006')}{this.state.orderInfo.id}
            </p>
            {/* 用车时间 */}
            <p>
            {this.t('orderdetails.k007')}{this.state.orderInfo.departTime ? moment(this.state.orderInfo.departTime).format('YYYY-MM-DD HH:mm:ss') : ''}
            </p>
            {/* 用车场景 */}
            {
              (() => {
                if(this.isSceneNameCn) {
                  return <p>{this.t('orderdetails.k035')}{this.state.orderInfo.sceneNameCn}</p>
                }
              })()
            }
            {/* 用车原因 */}
            {/* <div className={style.reasons}>
              用车原因：加班
              {
                this.showUseCarReason && !this.isChangeReason ? 
                <div onClick={this.useCarReason}>更改用车原因 <RightOutline fontSize={14} color={'#323b47'}/></div> : <></>
              }
            </div> */}
            {/* 里程 */}
            {
              (() => {
                if (this.state.orderInfo.travelDistance) {
                  if (this.state.orderInfo.travelDistance > 1) {
                    return <p>{this.t('orderdetails.k008')}{this.state.orderInfo.travelDistance}{this.t('orderdetails.k009')}</p>
                  } else {
                    return <p>{this.t('orderdetails.k008')}{(this.state.orderInfo.travelDistance * 1000)}{this.t('orderdetails.k010')}</p>
                  }
                }
              })()
            }
            {/* 时长 */}
            {
              (() => {
                if(this.state.orderInfo.duration) {
                  return <p>{this.t('orderdetails.k011')}{this.state.orderInfo.duration}</p>
                }  
              })()
            }
            {/* 用车类型 */}
            <p>{this.t('orderdetails.k012')}{serviceStateToCn(this.state.orderInfo.serviceType, this.t, this.state.orderInfo.charteredBusType)}</p>
            {/* 车辆信息 */}
            {this.state.orderInfo.vehicleNo ? <p>{this.t('orderdetails.k013')}{this.state.orderInfo.vehicleNo}{this.state.orderInfo.vehicleModel}</p> : ''}
            {/* 企业已支付 */}
            {
              (this.companyPaidAmount > 0 && !gc.process.isMeituan) || (this.companyPaidAmount > 0 && gc.process.isMeituan &&  this.state.orderInfo.isPay) ? 
              <p>{this.t('orderdetails.k077')}{this.companyPaidAmount} {this.t('orderdetails.k047')}</p> : <></>
            }
            {/* 个人已支付 */}
            {
              (this.personalPaidAmount > 0 && !gc.process.isMeituan) || (this.personalPaidAmount > 0 && gc.process.isMeituan &&  this.state.orderInfo.isPay) ?
              <p>{this.t('orderdetails.k078')}{this.personalPaidAmount} {this.t('orderdetails.k047')}</p> : <></>
            }
            {/* 个人待支付 */}
            {
              (this.state.orderInfo.personalPayableAmount > 0 && !gc.process.isMeituan) ?
              <p>{this.t('orderdetails.k079')}{this.state.orderInfo.personalPayableAmount} {this.t('orderdetails.k047')}</p> : <></>
            }
            {/* 施维雅自定义用车字段 */}
            {
              this.state.orderInfo?.customLabel?.map((item, index) => {
                return <p key={index} style={{ color: item.key.color }}><span>{item.key.txt}:</span> <span style={{color: item.value.color }}>{item.value.txt}</span></p>
              })
            }
            {
              this.isShowCarFeeDetail ? <div className={style.details_content} onClick={() => {
                this.setState({
                  showCarFeeDetail: true
                })
              }}>{this.t('orderdetails.k089')}<RightOutline color='#3C4858'/></div> : <></>
            }
            <Popup
                visible={this.state.showCarFeeDetail}
                getContainer={() => document.getElementsByClassName('Q0VHzm2T9EayOJNcobnha')[0]}
                onMaskClick={() => {
                  this.setState({
                    showCarFeeDetail: false
                  })
                }}
                position='bottom'
                bodyStyle={{ minHeight: '20%' }}
            >
                <div className={style.popupContainer}>
                  <div className={style.cost_description}>费用说明</div>
                  {
                    this.state.payDetail?.personPayAmount ? <div className={style.pay_description}>
                      <span>个人支付</span>
                      <span>¥{this.state.payDetail?.personPayAmount}</span>
                    </div> : <></>
                  }
                  {
                    this.state.payDetail?.entPayAmount ? <div className={style.pay_description}>
                      <span>企业支付</span>
                      <span>¥{this.state.payDetail?.entPayAmount}</span>
                    </div> : <></>
                  }
                  <div className={style.illustrate}>以下可用企业付额度取小计算</div>
                  {
                    this.state.payDetail && this.state.payDetail.amountLimitList ? <div className={style.box}>
                      {
                        this.state.payDetail && this.state.payDetail.amountLimitList && this.state.payDetail.amountLimitList.map(item => {
                          return (<div>
                            <div className={style.cell}>
                              <span>{item.typeValue}</span>
                              <span className={style.mark_color}>¥{item.limitAmount}</span>
                            </div>
                            <div className={style.illustrate}>{item.text}</div>
                          </div>)
                        })
                      }
                    </div> : <></>
                  }
                  
                  <div className={style.total}>
                    <span>合计</span>
                    <span>¥{this.state.orderInfo.totalAmount}</span>
                  </div>
                </div>
            </Popup>
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  }

  /**
   * 订单详情-行程取消
   */
  renderCellCancel() {
    return(
      <Collapse activeKey={this.state.activeCancelKey}>
        <Collapse.Panel onClick={() => {
          this.setState({
            cancelKey: '4',
            activeCancelKey: '4',
            activeDriverKey: '',
            activeEvaluateKey: '',
            activeDetailsKey: '',
            activeApprovalKey: '',
            driverKey: null, // 1
            evaluateKey: null, // 2
            detailsKey: null, // 3
            approvalKey: null,
            showHistory: true,
            isShowaCancel: false
          })

          if (this.state.cancelKey === '4' && this.state.activeCancelKey === '4') {
            this.setState({
              cancelKey: null,
              activeCancelKey: '',
              isShowaCancel: !this.state.isShowaCancel
            })
          }
        }} key={this.state.cancelKey} title={
          <div className={style.order_cancel}>
            {/* k058：行程已取消 */}
            <div className={style.title}>
              {this.t('orderdetails.k058')}
              <img className={style.deployment_img} alt='' src={images.deployment}/>
            </div>
            {
              this.state.orderInfo.showReplaceOrderBtn ? <p>
                {/* k059：订单已取消，您可以重新打车。 */}
                {this.t('orderdetails.k059')}
                </p> : ''
            }
          </div>
        }>
          <div>
            <div className={style.cancel_box}>
              <div className={style.cell_box}>
                  <div>
                    <img alt='' src={images.payTime}/>
                    <p>{this.state.orderInfo.departTime ? moment(this.state.orderInfo.departTime).format('YYYY-MM-DD HH:mm:ss') : ''}</p>
                  </div>
                  <div>
                    <img alt='' src={images.begin}/>
                    <p>{this.state.orderInfo.pickupLocationName}</p>
                  </div>
                  <div>
                    <img alt='' src={images.end}/>
                    <p>{this.state.orderInfo.destLocationName}</p>
                  </div>      
              </div>
              {
                this.isCancelCustomerService ? 
                <div className={style.customer_service} onClick={() => {
                  setTimeout(() => {
                    window.location.href = `tel:${customerServiceTelephone}`;
                  }, 200);
                }}>
                  <img alt='' src={images.customerService}/>
                </div> : <></>
              }
            </div>
            {
              this.state.orderInfo.showReplaceOrderBtn ? 
              <div className={style.pay}>
                <Button style={
                  {
                    'background-image': 'var(--button_background_color)',
                    '--border-color': 'var(--button_border_color)',
                    'background-color': 'var(--button_border_color)',
                    'color': 'var(--button_color)'
                  }
                } onClick={(e) => {
                  e.stopPropagation()
                  this.generateEstimate()
                }} disabled={false} block color='primary' size='large'>
                  {/* k060：重新打车 */}
                  {this.t('orderdetails.k060')}
                </Button>
              </div> : <></>
            }
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  }

  /**
   *
   *司机信息展示
   */
  renderOrderDriver() {
    return <div>
      <div className={style.cell}>
        <div className={style.left}>
          <div className={style.oval}>
            <img alt="" src={this.state.orderInfo?.driverAvatar ? this.state.orderInfo?.driverAvatar : images.oval}/>
          </div>
          <div className={style.msg}>
            <div>
              <span>{this.state.orderInfo.vehicleNo}</span>
              <StarFill color={this.getStarColor} />
              <span className={style.driver_level}>{this.state.orderInfo?.driverLevel ?? '5.0'}</span>
            </div>
            <p>{this.state.orderInfo.vehicleModel} {this.state.orderInfo.vehicleColor}</p>
            <span>{this.state.orderInfo.carSources}</span>
          </div>
        </div>
        {
          this.showComplaintCenter ? 
          <div className={style.right} onClick={() => {
            this.props.history.push(`/serviceCenter/${this.state.orderId}`)
          }}>
            <img alt="" src={images.customerService}/>
          </div> : <></>
        }
      </div>
      {
        !this.payInfo.isPay ? <div className={style.pay}>
        <Button style={
                  {
                    'background-image': 'var(--button_background_color)',
                    '--border-color': 'var(--button_border_color)',
                    'background-color': 'var(--button_border_color)',
                    'color': 'var(--button_color)'
                  }
                } onClick={ async (e) => {
          e.stopPropagation()
          // this.payOrder()
          try {
            Toast.show({
                icon: 'loading',
                content: this.t('payments.k014'),
                duration: 0,
                maskClickable: false
            })
            if (oauthType === '8' && ['meituan'].includes(appId)) {
              let {data} = await webService.ziru.getPayUrl(this.state.orderId)
              if (data.code === 0) {
                if (data.data) {
                  window.location.href = data.data
                } else {
                  this.setState({
                    orderInfo: {
                      ...this.state.orderInfo,
                      settling: 1
                    }
                  }, () => {
                    if (this.paySettling) {
                      this.timerSettling = setInterval(() => {
                        this.loadOrderDetails()
                      }, 1000);
                    }
                    this.setState({
                      orderInfo: {
                        ...this.state.orderInfo,
                        isPay: true
                      }
                    })
                    Toast.show({
                      content: this.t('orderdetails.k091'),
                    })
                  })

                }
              } else {
                console.log('获取支付连接失败 ==> orderId=' + this.state.orderId, data)
                console.log('appId', appId)
                Dialog.alert({
                    content: <div className={style.alert_message}>
                      {
                        gc.process.isMeituan ? data.message : this.t('payments.k028', {appid: appId})
                      }
                      {
                        ![-1, 0, 1].includes(data.code) && gc.process.isMeituan ? <div className={style.code}>{data.code}</div> : <></>
                      }
                    </div>,
                    confirmText: this.t('common.k003'),
                    getContainer: () => document.getElementsByClassName('_1GYOIcePvYVu_Cw_10nqBL')[0],
                    onConfirm: async () => {
                    }
                })
              }
            } else {
              this.props.history.push('/payments')
            }
          } catch(e) {
            console.log('err', e)
          } finally {
            Toast.clear()
          }
        }} disabled={this.paySettling} block color='primary' size='large'>
          {/* 立即支付 */}
          {
            this.paySettling ? this.t('orderdetails.k093') : this.t('orderdetails.k061')
          }
        </Button>
      </div> : <></>
      }
      
    </div>
  }

  /**
   *
   *已评价内容
   */
  renderOccurEvaluation() {
    return (
      <div>
        <div className={style.occur_evaluation}>
          {
            this.complaintList.map((item,index) => {
              return <span key={index}>{item.label}</span>
            })
          }
        </div>
        {/* k062: 补充评价：*/}
        {
          this.state.feedback ? <div className={style.evaluate}>{this.t('orderdetails.k062')}{this.state.feedback}</div> : <></>
        }
        {/* 评价点击投诉 */}
        {this.renderComplaintEntrance()}
      </div>

    )
  }

  /**
   *
   * 投诉入口
   */
  renderComplaintEntrance() {
    return (
      <div>
        {
          this.scoreInfo.score !== 5 && !this.isShowProgress? 
          <div className={style.complaint}>
            <div className={this.languageMaxWidth ?style.max_width : ''}>
              {/* k063: 抱歉给您带来不便的出行体验 */}
              {this.t('orderdetails.k063')}
              {/* k064: ,如有需要 */}
              {
                this.showComplaintCenter ? <span>{this.t('orderdetails.k064')}</span> : <></>
              }
            </div>
            {/* K065: 点击投诉 */}
              {
                this.showComplaintCenter ? <div>
                <span className={style.span} onClick={() => {
                    this.props.history.push(`/serviceCenter/${this.state.orderId}`)
                  }}>{this.t('orderdetails.k065')}</span>
                </div> : <></>
              }
          </div> : <></>
        }
      </div>
    )
  }

  /**
   *
   * 评价操作
   */
  renderNotOccurEvaluation() {
    return <div>
     
      {/* 评价 label */}
      <div className={style.label_box}>
        {
          this.loadFeedbackLabels.map((item,index) => {
            return <div className={item.checked ? style.label_bule :style.label} key={index} onClick={() => {
              this.handleLabel(item)
            }}>
              {item.label}
            </div>
          }) 
        }
      </div>
      {/* 评价输入框 */}
      {/* k066: 补充评价 */}
      {
        this.scoreInfo.score ? <div className={`${style.text} ${this.state.isOtherEvaluate ? style.show_red : ''}`}>
        <TextArea maxLength={50} placeholder={this.t('orderdetails.k066')} value={this.state.evaluateValue} onChange={(val) => {
            this.handleValue(val)
          }} autoSize={{ minRows: 3, maxRows: 5 }}/>
        </div> : <></>
      }
      {/* 评价按钮 */}
      {
        this.scoreInfo.score ? <div className={style.btn}>
          <Button style={
                  {
                    'background-image': 'var(--button_background_color)',
                    '--border-color': 'var(--button_border_color)',
                    'background-color': 'var(--button_border_color)',
                    'color': 'var(--button_color)'
                  }
                } disabled={!this.isEvaluateValue} block color='primary' onClick={() => {
            this.handleClickEvaluate()
          }} size='middle'>
            {this.t('orderevaluate.k012')}
          </Button>
        </div> : <></>
      }
    </div>
  }

  /**
   *
   * 评价内容
   */
  renderEvaluationContent() {
    if (this.state.feedback || (this.state.tpyes && this.state.tpyes.length > 0)) {
      return this.renderOccurEvaluation()
    } else {
      return this.renderNotOccurEvaluation()
    }
  }

  /**
   *
   * 获取历史审批记录
   */
  renderProcessRecords() {
    const { Step } = Steps
    return(
      <Collapse activeKey={this.state.activeApprovalKey}>
        <Collapse.Panel onClick={() => {
          this.setState({
            approvalKey: '5',
            activeApprovalKey: '5',
            activeDriverKey: '',
            activeEvaluateKey: '',
            activeCancelKey: '',
            activeDetailsKey: '',
            detailsKey: null,
            driverKey: null, // 1
            evaluateKey: null, // 2
            cancelKey: null, // 4
            showHistory: true,
            isShowApprovalHistory: false
          })
          if (this.state.approvalKey === '5' && this.state.activeApprovalKey === '5') {
            this.setState({
              approvalKey: null,
              activeApprovalKey: '',
              isShowApprovalHistory: !this.state.isShowApprovalHistory
            })
          }
        }} key={this.state.approvalKey} title={
          <div className={style.details_title}>
            {/* k067: 企业审批相关 */}
            {this.t('orderdetails.k067')}
            {/* <DownOutline fontSize={12}/> */}
            <img className={style.deployment_img} alt='' src={images.deployment}/>
          </div>
        }>
          <div className={style.box_approved}>
            <Steps current={this.approvalProgressCount} direction={'vertical'} style={{
            '--title-font-size': '17px',
            '--description-font-size': '15px',
            '--indicator-margin-right': '12px',
            '--icon-size': '22px',
          }}>
            {
             this.state.historyArray && this.state.historyArray.length > 0  ?
             <Step
               title={this.t('orderdetails.k068')} // k068: 历史审批
               description={
                 this.state.showHistory ? <div className={style.expand} onClick={() => {
                   this.setState({
                     showHistory: false
                   })
                 }}>{this.t('orderdetails.k069')}</div> // k069: 展开审批记录
                 : 
                 <div>
                   <Steps current={2} direction={'vertical'} style={{
                     '--title-font-size': '17px',
                     '--description-font-size': '15px',
                     '--indicator-margin-right': '12px',
                     '--icon-size': '22px',
                   }}>
 
                     {
                       this.state.historyArray.map((item, index) => {
                       return (
                         <div key={index} className={style.count}>
                          {/* k070: 第几次 */}
                           {this.t('orderdetails.k070', {index:  index+1})}
                           {
                             item.map((item, index)=> {
                               return <Step
                               title={
                                 <div className={style.approval_title}>
                                   <div>
                                     {item.nodeName} {item.showNumber ? `(${item.approvedNumber}/${item.totalNumber})` : ''}
                                   </div>
                                   <div className={style.approval_time}>
                                   {item.approvalTime ? moment(item.approvalTime).format('MM-DD HH:mm') : ''}
                                   </div> 
                                 </div>
                               }
                               description={
                                 item.approvalDetails.map((val, i) => {
                                   return <div key={i}>
                                     <div className={style.approval_name}>
                                      {
                                        val.approver && val.approver.length > 12 ? 
                                        <Ellipsis
                                          direction='end'
                                          content={val.approver}
                                          expandText={
                                            <>
                                              {/* 展开 */}
                                              {this.t('orderdetails.k071')}
                                              <DownOutline />
                                            </>
                                          }
                                          collapseText={
                                            <>
                                              {/* 收起 */}
                                              {this.t('orderdetails.k072')}
                                              <UpOutline />
                                            </>
                                          }
                                        /> : <div>{val.approver}</div>
                                      }
                                      {val.approvalState !== 0 ? <img src={this.loadApprovalDetailsIcon(val)} alt='' /> : ''}
                                     </div>
                                      {
                                        val.comment ? <div className={style.reason}>
                                          {val.comment && val.approvalState !== 4 ?  this.t('orderdetails.k092') : this.t('orderdetails.k074') }{val.comment}
                                          </div> : <></>
                                        }
                                   </div>
                                 })
                               }
                               icon={<img alt='' src={this.loadApproverIcon(item)}/>}
                               key={index}
                             />
                             })
                           }
                         </div>
                       )
                       })
                     }
                   </Steps>
                 </div>
               }
               icon={<img alt='' src={images.approved}/>}
             /> : <></>
            }
            {
              this.state.lastArray.map((item, index)=> {
                return <Step
                title={
                  <div className={style.approval_title}>
                    <div>
                      {item.nodeName} {item.showNumber ? `(${item.approvedNumber}/${item.totalNumber})` : ''}
                      {
                        this.state.isDisabled && (this.state.lastArray?.length - 1 === index)  ? 
                        <span className={style.anew_submit} onClick={() => {
                          this.useCarReason()
                        }}>{this.t('orderdetails.k073')}</span> : <></> // 重新提交
                      }
                    </div>
                    <div className={style.approval_time}>
                      {item.approvalTime ? moment(item.approvalTime).format('MM-DD HH:mm') : ''}
                    </div> 
                  </div>
                }
                description={
                  item.approvalDetails.map((val, i) => {
                    return <div key={i}>
                      <div className={style.approval_name}>
                        {
                          val.approver && val.approver.length > 12 ? 
                          <Ellipsis
                          direction='end'
                          content={val.approver}
                          expandText={
                            <>
                              {/* 展开 */}
                              {this.t('orderdetails.k071')}
                              <DownOutline />
                            </>
                          }
                          collapseText={
                            <>
                              {/* 收起 */}
                              {this.t('orderdetails.k072')}
                              <UpOutline />
                            </>
                          }
                          /> : <div>{val.approver}</div>
                        }
                       {val.approvalState !== 0 ? <img src={this.loadApprovalDetailsIcon(val)} alt='' />:''}
                      </div>
                      {
                      val.comment ? <div className={style.reason}>
                        {val.comment && val.approvalState !== 4 ?  this.t('orderdetails.k092') : this.t('orderdetails.k074') }{val.comment}
                        </div> : <></>
                      }
                    </div>
                  })
                }
                icon={<img alt='' src={this.loadApproverIcon(item)}/>}
                key={index}
              />
              })
            }
          </Steps>
          </div>
        </Collapse.Panel>
      </Collapse>
    )
  }


  render() {
    return <>
      <MessageBox ref={(dom) => { this.messageBoxRef = dom; }}  history={this.props.history}></MessageBox>
      {
        this.state.showLoding ? <LoadingMask show={this.state.showLoding} /> :
        <div className={style.wrap}>
          {/* 地图 */}
          {this.renderMap()}
          {this.renderOrderState()}
        </div>
      }
    </>
  }

  async componentDidMount() {
    try {
      let res = await this.loadOrderDetails()
      if (res === 'REPLACR_TO_RUNING' || res === 'REPLACR_TO_PAY') {
        return;
      }
      await this.loadFeedbackLabel()
      await this.loadOrderFeedback()
      // await this.loadOrderProcessRecords()
      await this.loadTMap()
      

      if (this.paySettling) {
        this.timerSettling = setInterval(() => {
          this.loadOrderDetails()
        }, 1000);
      }

    } catch (e) {
      console.log(e)
    }
  }

  componentWillUnmount() {
    if (this.timerSettling) {
      clearInterval(this.timerSettling)
    }
    Dialog.clear()
  }

  /**
   * 
   * 详情获取
   */
  loadOrderDetails = async () => {
    const { id } = this.props.match.params
    let d;
    try {
      d = await webService.order.getOrderDetails({orderId:String(id)});
    } catch (err) {
      loger.debug('详情获取', err.message ? err.message : this.t('orderdetails.k029'))
    }
    let data = d.data.data;
    if (d.data.code === 0) {
      if (data.orderInfo.state < 5 && gc.process.isMeituan) {
        this.props.history.replace('/runing/' + id)
        return 'REPLACR_TO_RUNING'
      }

      // 判断如果开启真实路径后加载采集点接口
      if(this.isPathReal && data.orderInfo.state === 6) {
        await this.getLocusPoint(data.orderInfo.coreOrderId)
      }
      if ([5, 6].includes(data.orderInfo.state)) {
        this.setState({
          driverKey: '1',
          activeDriverKey: '1'
        })
      }
      if (data.orderInfo.state === 7) {
        this.setState({
          cancelKey: '4',
          activeCancelKey:'4'
        })
      }
      
      this.setState({
        orderInfo: data.orderInfo,
        isDisabled: data.resubmit,
        auditData:  data.approveHistory ? data.approveHistory : [],
        reasonVal:  data.orderInfo.useCarReason,
        orderId: id,
        // eslint-disable-next-line
        voucherMoney: data.bill && data.bill[0]?.billMain?.voucherMoney || 0,
        complaintInfo: data.complaint,
        showAbnormal: data.showAbnormal,
        jumpAbnormal: data.jumpAbnormal,
        // eslint-disable-next-line
        billMain: data.bill && data.bill[0]?.billMain || {},
        // eslint-disable-next-line
        isPrepayment: data.bill && data.bill[0]?.isPrepayment || false,
        // eslint-disable-next-line
        isRefund: data.bill && data.bill[0]?.isRefund || false,
        // eslint-disable-next-line
        individualNeedPay: data.bill && data.bill[0]?.individualNeedPay || null,
        // eslint-disable-next-line
        refundMoney: data.bill && data.bill[0]?.refundMoney || null,
        historyArray: data.approvalRecords?.historyArray ?? [],
        lastArray: data.approvalRecords?.lastArray ?? [],
        remainingCnt: data.remainingCnt,
        isActiveComplaint: data.isActiveComplaint,
        isAutoComplaint: data.isAutoComplaint,
        payDetail: data.payDetail,
        showDestFeedback: data.showDestFeedback
      }, () => {
        if (!this.paySettling) {
          clearInterval(this.timerSettling)
        }
      })
      this.setState({
        showLoding: false
      })
    }
  }

  /**
   * 
   * 获取评价 label
   */
  loadFeedbackLabel = async () => {
    let res = await webService.order.getFeedbackLabels();
    if(res.data.code === 0) {
      res.data.data.evaluate.forEach(item => {
        item.children.forEach(val => {
          val.value = val.id
          val.checked = false
        })
      })
      this.setState({
        feedbackLabels: res.data.data.evaluate
      })
    }
  }

  /**
   * 
   * 获取评价内容
   */
  loadOrderFeedback = async () => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.getOrderFeedback({orderId:id, type: 1});
    if (data.code === 0) {
      if (data.data.feedback || (data.data.tpyes && data.data.tpyes.length > 0)) {
        this.setState({
          evaluateKey: '2'
        })
      }
      this.setState({
        score: data.data.score,
        realScore: data.data.score,
        tpyes: data.data.tpyes,
        feedback: data.data.feedback
        // feedbackInfo: data.data.feedbackInfo
      })
    }
  }

  /**
   * 
   * 获取订单详情审批记录
   */
  loadOrderProcessRecords = async () => {
    const {id} = this.props.match.params;
    let {data} = await webService.order.getProcessRecords({orderId: id});
    if (data.code === 0) {
      this.setState({
        historyArray: data.data?.historyArray ?? [],
        lastArray: data.data?.lastArray ?? []
      })
    }
  }

  /**
   * 地图实例
   */
  loadTMap = async () => {
    await this.iPathMapRef.initMap()
    this.iPathMapRef.setDraggable(false)
    this.iPathMapRef.setScrollable(false)
    await this.loadMarker()
  }

  /**
   * 
   * 显示起点终点和连线 
   */
  loadMarker = async () => {
    if (!this.state.orderInfo.departLng) {
      return
    }
    if (this.isPathReal && this.state.polyline.length > 0) {
      if (this.state.orderInfo.state === 6 && this.state.polyline.length > 1) {
        setTimeout(() => {
          let element = document.getElementById('details')
          let height = element && element.clientHeight + 40;
          this.iPathMapRef.drawDrivingMoveAlong(this.state.polyline, 'begin_icon', 'end_icon', this.state.orderInfo.pickupLocationName, this.destLocationName, this.isPathRealAnimation, this.state.orderInfo.passingPoints, height)
        }, 10)
      } else {
        setTimeout (() => {
          let element = document.getElementById('details')
          let height = element && element.clientHeight + 40;
          this.iPathMapRef.addDriving({
            lat: this.state.orderInfo.departLat,
            lng: this.state.orderInfo.departLng,
            poi: this.state.orderInfo.departPoi,
            locationName: this.state.orderInfo.pickupLocationName
          }, {
            lat: this.state.orderInfo.destLat,
            lng: this.state.orderInfo.destLng,
            poi: this.state.orderInfo.destPoi,
            locationName: this.state.orderInfo.destLocationName
          }, this.state.orderInfo.serviceType, this.state.orderInfo.passingPoints, height)
        }, 10)
      }
    } else {
      setTimeout(() => {
        let element = document.getElementById('details')
        let height = element && element.clientHeight + 40;
        this.iPathMapRef.addDriving({
          lat: this.state.orderInfo.departLat,
          lng: this.state.orderInfo.departLng,
          poi: this.state.orderInfo.departPoi,
          locationName: this.state.orderInfo.pickupLocationName
        }, {
          lat: this.state.orderInfo.destLat,
          lng: this.state.orderInfo.destLng,
          poi: this.state.orderInfo.destPoi,
          locationName: this.state.orderInfo.destLocationName
        }, this.state.orderInfo.serviceType, this.state.orderInfo.passingPoints, height)
      }, 10)
    }
  }

  /**
   * 
   * 真实采集点订单结束后才会显示
   */
  getLocusPoint = async (coreOrderId) => {
    const { id } = this.props.match.params
    let {data} = await webService.order.getLocusPoint({coreOrderId:coreOrderId, orderId: id, start: 0, stop: ''})
    if (data.data && data.data.length > 0) {
      let polyline = []
      let lastPolyline = data.data;
      for(let i in data.data) {
        polyline.push(Number(data.data[i].lat))
        polyline.push(Number(data.data[i].lng))
      }
      this.setState({
        polyline: polyline,
        locationGeo: {
          ...this.state.locationGeo,
          lat: lastPolyline[lastPolyline.length -1].lat,
          lon: lastPolyline[lastPolyline.length -1].lng
        }
      })
      await this.getPosition()
    }
  }

  /**
   *
   *匿名评价
   */
  handleClickEvaluate() {
    let complaint = this.loadFeedbackLabels.filter((item) => item.checked)
    this.setState({
      isEvaluateDisabled: false
    })
    if (!this.scoreInfo.score) {
      Toast.show({
        content: this.t('orderevaluate.k013'),
      })
      this.setState({
        isEvaluateDisabled: true
      })
    } else if(this.otherEvaluateChecked.length > 0 && !this.state.evaluateValue) {
      this.setState({
        isOtherEvaluate: true,
        isEvaluateDisabled: true
      })
    } else {
      const {id} = this.props.match.params;
      // 走评价接口
      let params = {
        orderId: id,
        feedback: this.state.evaluateValue,
        score: this.scoreInfo.score,
        complaints: complaint,
        type: this.state.feedbackType
      }
      // webService.logger.report(id, 'newDetails', '提交评价参数 => type = ', this.state.feedbackType)
      this.setSubmitFeedback(params, complaint)
    }
  }

  /**
   * 提交评级
   */
  setSubmitFeedback = async (params, complaint) => {
    let {data} = await webService.order.setFeedback(params)
    if (data.code === 0) {
      Toast.show({
        content: this.t('orderevaluate.k015'),
      })
      
      if (complaint?.length === 0 && !this.state.evaluateValue) {
        this.setState({
          evaluateKey: null, // 2
          activeEvaluateKey: '',
          isOtherEvaluate: false,
          isEvaluateDisabled: true
        })
      }
      this.loadOrderDetails()
      this.loadOrderFeedback()
    } else {
      this.setState({
        isEvaluateDisabled: true
      })
    }
  }

  /**
   * 获取真实地点名称
   */
  getPosition = async () => {
    await getPosition4Element({lon: this.state.locationGeo.lon, lat: this.state.locationGeo.lat}).then(c5 => {
      if (c5.address && c5.name && c5.lat && c5.lon) {
        let name = c5.name;
        this.setState({
          locationGeo: {
            ...this.state.locationGeo,
            name: name
          }
        })
      } else {
        this.mainStore.setNameAddrEmpty({name: this.t('orderdetails.k075'), address: this.t('orderdetails.k075')})
      }
    }).catch(err => {
      console.log(err)
    })
  }

   /**
   * 选择评价 label
   */
  handleLabel = (v) => {
    this.state.feedbackLabels.forEach(item => {
      item.children.forEach(val => {
        if (v.id === val.id) {
          val.checked = !val.checked
        }
        if ((v.label === '其他' || v.label === 'other') && (val.label === '其他' || val.label === 'other')) {
          val.show = !val.show
          if(!val.show) {
            this.setState({
              isOtherEvaluate: false
            })
          }
        }
      })
    })
    this.setState({
      feedbackLabels: this.state.feedbackLabels
    })
  }

  /**
   * 支付
   */
  payOrder = () => {
    console.log('dasdasdadasdasd');
  }

  /**
   * 提交用车原因
   */
  useCarReason = () => {
    if (!this.state.isDisabled) {
      Dialog.show({
        getContainer: false,
        closeOnAction: true,
        bodyClassName: `${style.prompt}`,
        actions: [
          {
            key: 'cancel',
            text: this.t('orderdetails.k023'),
          }
        ],
        title: this.t('orderdetails.k024'),
        content: (<>
            <div>
                <TextArea placeholder={this.t('orderdetails.k027')} disabled={this.state.isDisabled} maxLength={100} rows={3} defaultValue={this.state.reasonVal} 
                    onChange={(val) => {
                      this.setInput(val)
                    }} />
                    {/* 当前还可修改 次 */}
                {
                  this.state.remainingCnt ? <div className={style.edit}>{this.t('orderdetails.k086')} <span>{this.state.remainingCnt}</span> {this.t('orderdetails.k087')}</div> : <></>
                }
            </div>
        </>)
      })
    } else {
      Dialog.confirm({
          getContainer: false,
          bodyClassName: `${style.prompt}`,
          confirmText: this.t('orderdetails.k025'),
          cancelText: this.t('orderdetails.k026'),
          title: this.t('orderdetails.k024'),
          content: (<>
              <div>
                  <TextArea placeholder={this.t('orderdetails.k027')} disabled={!this.state.isDisabled} maxLength={100} rows={3} defaultValue={this.state.reasonVal} 
                      onChange={(val) => {
                        this.setInput(val)
                      }} />
                  {
                    (() => {
                      if (this.state.isDisabled && this.state.auditData.length > 0) {
                        return  <span className={style.approval}>{this.t('orderdetails.k028')}</span>
                      }
                    })()
                  }
                  {/* 当前还可修改 次 */}
                  {
                    this.state.remainingCnt ? <div className={style.edit}>{this.t('orderdetails.k086')} <span>{this.state.remainingCnt}</span> {this.t('orderdetails.k087')}</div> : <></>
                  }
              </div>
          </>),
          onConfirm: ()=> {
              this.changeConfirm()
          }
      })
    }
  }

  /**
   *获取输入用车原因
   * @param {*} val
   */
  setInput = (val) => {
    this.setState({
      reasonVal: val
    })
  }

  /**
   *
   *获取评价内容
   *@param {*} val
   */
  handleValue(val) {
    this.setState({
      evaluateValue: val
    })
  }

  orderPay = async () => {
    let url = window.location.href;
    let order = [{id: this.state.orderId}]
    let d = await webService.order.pay({orders: order, redirectUri: url, paymentType: payType()});
    Toast.clear()
    if (d.data.code === 0) {
        if(['zhonghai', 'XCX032424'].includes(appId)) {
          ipay(d.data.data, '');
        } else if (oauthType === '1') {
          ipay('', d.data.data.payUrl);
        } else {
          ipay(d.data.data, `/ordernewdetails/${this.state.orderId}`);
        }
        setTimeout(() => {
          localStorage.setItem('isPay', true);
          // payOrderid 京东使用的
          localStorage.setItem('payNo', d.data.data.tranOrderNo ? d.data.data.tranOrderNo : d.data.data.payOrderid);
          if (dge() === 'jd-mini') {
            this.getPayState()
          }
        })
    } else {
        Dialog.alert({
            content: d.data.message,
            confirmText: this.t('common.k003')
        })
    }
  }

  /**
   * 获取支付状态
   */

  getPayState = async () => {
    // 支付标识
    let isPay = localStorage.getItem('isPay')

    // 支付单号
    let payNo = localStorage.getItem('payNo')

    let params = {
      PayOrderid: payNo
    }

    console.log('isPay', isPay)

    console.log('payNo', payNo)
    
    if (isPay) {

      Toast.show({
        icon: 'loading',
        content: this.t('payments.k014'),
        duration: 0
      })

      let result = await this.getOrderPayState(params)

      if (result.data.code === 0) {
        Toast.clear();
        this.setState({
          payStatus: result.data.data.payState
        }, () => {
          if (this.state.payStatus === 1) {
            Dialog.show({
              title: this.t('payments.k012'),
              content: (
                <>
                  <div className={style.confirmPay}>{this.t('payments.k010')}</div>
                </>
              ),
              actions: [
                [
                  {key: 'go_set', text: this.t('payments.k001'), 
                  onClick: () => {
                      localStorage.setItem('isPay', '');
                      localStorage.setItem('payNo', '')
                  }}, 
                  {key: 'cancel', text: this.t('payments.k002'), bold: true, onClick: async () => {

                    let result = await this.getOrderPayState(params)
                    
                    if (result.data.code === 0) {
                      if (result.data.data.payState === 1) {
                        Dialog.show({
                          title: this.t('payments.k012'),
                          content: (
                            <>
                              <div className={style.service}>
                                {this.t('payments.k011')} <span>{customerServiceTelephone}</span>
                              </div>
                            </>
                          ),
                          actions: [
                            {
                              key: 'cancel',
                              text: this.t('payments.k013'),
                              onClick: () => {
                                localStorage.setItem('isPay', '');
                                localStorage.setItem('payNo', '')
                              }
                            }
                          ],
                          closeOnAction: true
                        })
                      } 
                      else if (result.data.data.payState === 2) {
                        // 支付成功
                        this.setState({
                          groupKey: 'payed',
                          currentState: 2
                        })
                        localStorage.setItem('isPay', '');
                        localStorage.setItem('payNo', '')
                      }
                    }
                  }}
              ]],
              closeOnAction: true
            })
          } else if (result.data.data.payState === 2) {
            // 支付成功
            this.setState({
              groupKey: 'payed',
              currentState: 2
            })
            localStorage.setItem('isPay', '');
            localStorage.setItem('payNo', '')
          }
        })
      } else {
        Toast.clear()
      }
    }
  }


  /**
   *
   *确认
   */
  changeConfirm = async () => {
    if (!this.state.reasonVal) {
      Toast.show({
        content: this.t('orderdetails.k030'),
      })
    } else {
      const {id} = this.props.match.params;
      let params = {
        orderId: id,
        reason: this.state.reasonVal
      }
      let {data} = await webService.order.setReason(params)
      if (data.code === 0) {
        this.setState({
          isVisible: false,
          isDisabled: true,
          reasonVal: this.state.reasonVal
        })
        Toast.show({
          content: this.t('orderdetails.k031'),
        })
      } else {
        Toast.show({
          content: data.message,
        })
      }
    }
  }

  loadApproverIcon = (item) => {
    if(item.approvedNumber > 0 && item.approvalStatus === 2) {
      return images.approved
    } else if (item.approvedNumber > 0 && item.approvalStatus === 4) {
      return images.approvalFailed
    } else {
      return images.pendingApproval
    }
  }

  loadApprovalDetailsIcon = (item) => {
    if(item.approvalState === 2) {
      return images.approvedSuccess
    } else if (item.approvalState === 4) {
      return images.approvalFailed
    }
  }

  /**
   *
   * 确认是否可以跳转预估页面
   */
  generateEstimate = () => {
    let order_params = JSON.parse(base64ToString(this.state.orderInfo.orderParams));
    let choosedEstimates = order_params.estimateStore.choosedEstimates
    order_params.estimateStore.choosedEstimates = choosedEstimates.map(a => {
      let _arr = a.split(',')
      return {
        carSource: _arr[0],
        carSourceId: Number(_arr[1]),
        carLevel: Number(_arr[2])
      }
    })
    this.mainStore.reset(order_params.mainStore,
    this.globalStore.userInfo.phone !== order_params.confirmStore.passenger.phone ? order_params.confirmStore.passenger : undefined);
    // 服务端校验能否进行预估价格
    let cep = generateEstimate(this.mainStore.serverType, this.mainStore.scene, this.mainStore.beginAddr, this.mainStore.endAddr, undefined, this.mainStore.preApply, this.mainStore.charteredBusType, this.mainStore.pathways);
    webService.order.preCheck(cep).then((d) => {
      if (d.data.code) {
        if (d.data.data && d.data.data.forwardUrl) {
          Dialog.show({
            title: this.t('common.k004'),
            content: d.data.data.fprwardBtnText,
            actions: [
              [
                {
                  key: 'go_set', text: <div className={style.btn_details}>{this.t('common.k006')}</div>,bold: true, 
                  onClick: () => {
                    this.showMessageBox(d.data.data.forwardUrl)
                  }
                },
                {
                  key: 'cancel', text: <div className={style.btn_close}>{this.t('common.k005')}</div>, onClick: () => {
                    this.props.history.go(-1)
                  }
                }
              ]
            ],
            closeOnAction: true
          })
        } else {
          Dialog.alert({
            content: d.data.message,
            confirmText: this.t('common.k003')
          })
        }
      } else {
          // 跳转到预约界面
          setTimeout(() => {
              this.estimateStore.clear();
              this.props.history.push('/estimate');
          }, 500);
      }
    }); 
  }
  
  numSub(num1, num2) { 
    let baseNum, baseNum1, baseNum2; 
    let precision;// 精度 
    try { 
    baseNum1 = num1.toString().split(".")[1].length; 
    } catch (e) { 
    baseNum1 = 0; 
    } 
    try { 
    baseNum2 = num2.toString().split(".")[1].length; 
    } catch (e) { 
    baseNum2 = 0; 
    } 
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2)); 
    precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2; 
    return Math.abs((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision); 
  }

  add(...val) {
    let max = 0
    let count = 0
    for (let i = 0; i < val.length; i++) {
      const strVal = val[i].toString()
      const index = strVal.indexOf('.')
      let num = 0
      if (index > -1) {
        num = strVal.length - 1 - index
        max = num > max ? num : max
      }
    }
    for (let i = 0; i < val.length; i++) {
      count += Math.round(val[i] * Math.pow(10, max))
    }
    return count / Math.pow(10, max)
  }

  // 显示错误提示信息
  showMessageBox(url){
    Dialog.clear(); 
    this.messageBoxRef.show(url)
    let fr = new ForgeRouter(() => {
      this.messageBoxRef.hide('messageBoxRef')
    });
    fr.onpopstate();
  }

  // 上报位置是否正确
  uploadLocationFeedback = async (bool) => {
    let res = await webService.order.uploadLocationFeedback({
      orderId: this.state.orderId,
      isCorrect: bool
    });
    if (res.data.code === 0) {
      this.loadOrderDetails()
    }
  }
}

export default withTranslation()(OrderNewDetails)