//eslint-disable-next-line 
export default {
  main: {
    k000: 'en',
    k001: "You'll get on here",
    k002: 'Failed to get location',
    k003: 'This area only supports a fixed pick-up location',
    k004: 'Failed to inquiry company information',
    k005: 'Reading',
    k006: 'Failed to locate your position, please manually select your address.',
    k007: 'Failed to locate your position, please check your network connections!',
    k008: 'Ongoing Trips',
    k009: 'Approval',
    k010: 'Personal Payment',
    k011: 'My Trips',
    k012: 'invoice',
    k013: 'Trip Scenarios',
    k014: 'Personal Settings',
    k015: 'Guidance',
    k016: 'Coupons',
    k017: 'Enterprise Accounts',
    k018: 'Trip Report',
    k019: 'Please select the trip scenario.',
    k020: 'Please set a pick-up location',
    k021: 'Please select a car-booking time',
    k022: 'Please select a flight',
    k023: 'Please select a service',
    k024: 'You have {{count}} on-going trips.',
    k025: '',
    k026: '{{ who }}Waiting for a response',
    k027: 'The driver is on the way to pick you up',
    k028: 'Driver has arrived.',
    k029: 'Ongoing',
    k030: 'Car scene',
    k031: 'Loading',
    k032: 'No trip scenario available',
    k033: 'loading failed',
    k034: 'Available Balance',
    k035: 'CNY',
    k036: 'unlimited',
    k037: '(Return after approval)',
    k038: 'Available Vehicle Types',
    k039: 'Workday',
    k040: 'View Details',
    k041: 'Available date:',
    k042: 'unlimited',
    k043: 'To',
    k044: 'Available time:',
    k045: 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday',
    k046: 'Weekend & Holidays',
    k047: 'Single trip',
    k048: 'Round trip',
    k049: 'Anywhere',
    k050: 'Manager:',
    k051: 'Economy class',
    k052: 'Comfort class',
    k053: 'Business class',
    k054: 'Luxury class',
    k055: 'Please select pickup time',
    k056: 'Month',
    k057: 'th',
    k058: 'Year',
    k059: '',
    k060: 'minute',
    k061: "City's Chinese Name or Pinyin",
    k062: 'Cancel',
    k063: 'Current city:',
    k064: 'Current location:',
    k065: 'Where to go from',
    k066: 'Please enter the flight number',
    k067: 'Please enter the flight number',
    k068: 'Confirm',
    k069: 'The driver will receive the dynamic flight status to pick you up on time',
    k070: 'Select a flight',
    k071: 'Week',
    k072: 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday',
    k073: 'No flight information',
    k074: 'Hotline',
    k075: 'Click to view',
    k076: 'Loading',
    k077: 'weeks',
    k078: 'Single trip limit:',
    k079: 'City:',
    k080: 'Location:',
    k081: 'Apply for Trips',
    k082: 'Pre-trip Approval',
    k083: 'Positioning in progress',
    k084: 'Where are you goning?',
    k085: 'Where are you going?',
    k086: 'unknown',
    k087: 'Welcome',
    k088: 'Please select your region',
    k089: 'Based on your choice，',
    k090: 'select your region',
    k091: 'Get on the bus here',
    k092: 'Do not board the bus at this address',
    k093: 'You are currently located in',
    k094: 'For you',
    k095: 'Recommend the best place to ride',
    k096: 'Comfirm pick-up location',
    k097: 'Address selection box',
    k098: 'Incomplete account information',
    k099: 'Please set your cell phone number first',
    k0100: 'Go to settings',
    k0101: 'To be allocated',
    k0102: 'to be serviced.',
    k0103: 'Cancelled',
    k0104: 'Concierge cancellation',
    k0105: 'Unknown status',
    k0106: 'wait for...',
    k0107: 'The driver is on the way, please wait',
    k0108: 'Vehicle has arrived.',
    k0109: 'Trip starts.',
    k0110: 'End of service',
    k0111: 'Trip Completed',
    k0112: 'Your trip has been cancelled by the system.',
    k0113: 'Cancelled',
    k0114: 'Waiting for the driver',
    k0115: 'Driver arrives',
    k0116: 'Ongoing',
    k0117: 'End of service',
    k0118: 'Trip Completed',
    k0119: 'Cancelled',
    k0120: 'Enter address',
    k0121: 'Common address 1',
    k0122: 'Common address 2',
    k0123: 'Tap to set',
    k0124: 'Failed to set',
    k0125: 'Failed to delete',
    k0126: 'Please enter your train information',
    k0127: 'Train information',
    k0128: 'Please select the train arrival date',
    k0129: 'query',
    k0130: 'Via parking station',
    k0131: 'Start using the car',
    k0132: "There is no car scene",
    k0133: "Currently there is no car permission",
    k0134: 'The reservation butler is waiting for you...',
    k0135: 'Get off here',
    k0136: 'Confirm drop-off location',
    k0137: 'Do not get off at this address',
    k0138: 'Recommended drop-off points',
    k0139: 'Recommend the best place to drop off',
    k0140: 'The location has multiple suitable drop-off locations',
    k0141: 'You have selected',
    k0142: 'Chartered bus',
    k0143: 'Please select a charter time',
    k0144: 'Pricing rule',
    k0145: 'We are continuing to book a car for you...',
    k0146: 'Home',
    k0147: 'Bus',
    k0148: 'Obtaining boarding location',
    k0149: 'No address found, please check the input',
    k0150: 'Pending order',
    k0151: 'You currently have an unpaid order, complete the payment to avoid affecting your subsequent use of the car',
    k0152: 'Go to pay',
    k0153: 'Map loading...',
    k0154: 'Show the remaining{{length}}results',
    k0155: 'Failed to locate your position',
    k0156: 'informed consent agreement',
    k0157: 'I have read and agree to the 《Software Usage Agreement》',
    k0158: 'Please check the informed consent',
    k0159: 'Software usage agreement',
    k0160: 'I have read and agree to the 《Software Usage Agreement》',
    k0161: 'Please read the software usage agreement',
    k0162: 'payment center',
    k0163: 'My Feedback',
    k0164: 'Transit point',
    k0165: 'Please enter the transit point',
    k0166: 'Please add the train number or date information',
    k0167: 'Please arrive at the pick-up place around {{time}} on the {{day}}',
    k0168: 'Cell phone',
    k0169: 'No car trips available',
    k0170: 'Please select a train number',
    k0171: 'You have an abnormal itinerary to be confirmed, please confirm before using the car',
    k0172: 'Go to confirm',
    k0173: 'Read and agree',
    k0174: 'Privacy agreement',
    k0175: 'Real-time',
    k0176: 'Chartered bus',
    k0177: 'Scheduled',
    k0178: 'Airport',
    k0179: 'Train Pickup',
  },
  account: {
    k001: 'Available Balance',
    k002: 'Amount frozen',
    k003: 'Switch accounts',
    k004: 'Top-up amounts',
    k005: 'month',
    k006: 'Type',
    k007: 'Amount',
    k008: 'No data yet',
    k009: 'start:',
    k010: 'end:'
  },
  accountCharge: {
    k001: 'Available Balance',
    k002: 'Top-up amounts',
    k003: 'CNY',
    k004: 'Other amounts',
    k005: 'invitation code:',
    k006: 'Please enter the invitation code (optional)',
    k007: 'The invitation code format is incorrect, it should be no more than 10 characters',
    k008: 'I have read and agree to the terms',
    k009: '<E-Car Commercial Terms>',
    k010: 'Top up now',
    k011: 'Please enter the correct invitation code',
    k012: 'Please select the top-up amount',
    k013: 'Please read and agree to the <E-Car Terms> first',
    k014: 'Loading',
    k015: 'Please enter an integer',
    k016: 'Loading',
  },
  approval: {
    k001: 'Departure：',
    k002: 'Dest：',
    k003: 'Request Time：',
    k004: 'Miles：',
    k005: 'Duration：',
    k006: 'Pending approval',
    k007: 'Approved',
    k008: 'CNY',
    k009: 'Select all',
    k010: 'reject',
    k011: 'Pass',
    k012: 'month：',
    k013: 'Total amount：',
    k014: 'Order exception',
    k015: 'Order number：',
    k016: 'KM',
    k017: 'meter',
    k018: 'Trip Scenarios：',
    k019: 'Reasons for the trip：',
    k020: 'No more',
    k021: 'Trip scenario details',
    k022: 'Personnel details',
    k023: 'Risk Warning',
    k024: 'Got it!',
    k025: 'No pending approval items',
    k026: 'No approved items',
    k027: 'Loading',
    k028: 'Operated successfully',
    k029: 'No pending approval items',
    k030: 'Please select orders to approve',
    k031: 'submit',
    k032: 'Cancel',
    k033: 'Please enter a reason for the rejection',
    k034: 'Please confirm that you would like to approve the selected orders?',
    k035: 'KM',
    k036: 'Loading',
    k037: 'Failed',
    k038: 'pass',
    k039: 'refuse',
    k040: 'Unknown status',
    k041: 'Order exception',
    k042: 'This trip exceeds the following standards',
    k043: 'operation failed',
    k044: 'Trip Type：',
    k045: 'Half-day pack',
    k046: 'full-time',
    k047: 'Pre-departure application Number:',
    k048: '该订单已更换审批人',
  },
  approvalDetails: {
    k001: 'Fare Details',
    k002: 'CNY',
    k003: 'If you have any questions, please call {{serviceNumber}}',
    k004: 'Approval logs',
    k005: 'Final approval status:',
    k006: 'Time',
    k007: 'Approver:',
    k008: 'reason:'
  },
  callPolice: {
    k001: 'In case of danger, please keep calm and call the police according to law.',
    k002: 'File a false report will be held liable under the law.',
    k003: 'itinerary information',
    k004: 'current reference position',
    k005: 'we will take the following measures right now when you call the police.',
    k006: 'please provide itinerary information according the request of the police.',
    k007: 'Notify emergency contact.',
    k008: 'Add',
    k009: '24-Hour customer service line.',
    k010: 'call 110.',
    k011: 'Failed to locate your position',
    k012: 'urgent'
  },
  chooseCoupon: {
    k001: 'Usable when exceeds {{money}} Yuan',
    k002: 'enterprise',
    k003: 'individual',
    k004: 'View Details',
    k005: 'Available Platforms:',
    k006: 'Available Vehicle Types:',
    k007: 'Available Cities:',
    k008: 'except ',
    k009: 'unlimited',
    k010: 'not use coupons',
    k011: 'Coupon usage would be based on the actual trip result',
    k012: 'Coupon is unavailable',
    k013: 'unavailable in current city',
    k014: 'unavailable for current car types'
  },
  chooseScene: {
    k001: 'Available Balance',
    k002: 'CNY',
    k003: 'unlimited',
    k004: 'Return after approval',
    k005: 'View Details',
    k006: 'Available date:',
    k007: 'unlimited',
    k008: 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday',
    k009: 'Workday',
    k010: 'Weekend & Holidays',
    k011: 'Single trip',
    k012: 'Round trip',
    k013: 'Anywhere',
    k014: 'Manager',
    k015: 'Economy class',
    k016: 'Comfort class',
    k017: 'Business class',
    k018: 'Luxury class',
    k019: 'weeks',
    k020: 'Available Vehicle Types',
    k021: 'Available time:'
  },
  confirm: {
    k001: 'Value-added services',
    k002: 'Trip',
    k003: 'Reason for the trip',
    k004: '(Optional)',
    k005: '{{validCount}}Coupons available',
    k006: 'No coupons available',
    k007: 'Coupons',
    k008: 'Departure',
    k009: 'Dest',
    k010: "Passenger's TEL",
    k011: "Please enter the passenger's cell phone number",
    k012: 'Trip Scenarios：',
    k013: 'Project',
    k014: 'Select project',
    k015: 'No project',
    k016: 'Enterprise Accounts ',
    k017: 'I have read and agree to the',
    k018: '<Enterprise Travel Terms>',
    k019: '<informed consent agreement>',
    k020: 'Confirm order',
    k021: 'Reselect Car Types',
    k022: 'Emergency contact instruction',
    k023: "You haven't set emergency contact. To use trip guardian service, please go to 'Settings' to fill in the emergency contact",
    k024: 'Cancel',
    k025: 'Go to Settings',
    k026: 'Please enter the correct phone number first',
    k027: 'Please enter the reason for the trip first',
    k028: 'Please read and agree to the <E-Car Commercial Terms> first',
    k029: 'Loading',
    k030: 'You have stayed in the current page for too long, please return to the previous page to re-estimate the trip price',
    k031: 'Available for over {Yuan}',
    k032: 'enterprise',
    k033: 'individual',
    k034: 'View Details',
    k035: 'Available Platforms',
    k036: 'Available Vehicle Types',
    k037: 'Available Cities',
    k038: 'There is unlimited except {city}',
    k039: 'Application form',
    k040: '<Enterprise Travel Terms>',
    k041: 'Please enter',
    k042: 'Please enter correctly',
    k043: 'Please select',
    k044: 'Prioritizing low-carbon travel can ensure your travel efficiency while prioritizing orders to nearby clean energy models',
    k045: 'Okay, thanks for the heads up',
    k046: 'Recommend choosing low-carbon travel',
    k047: 'Prioritize low-carbon vehicle travel',
    k048: 'Address book',
    k049: 'Failed to call address book',
    k050: 'Please go to the personal center to import the address book',
    k051: 'Confirm',
    k052: 'Please fill in the item number',
    k053: 'Item number',
    k054: 'Please enter the item number or no item',
    k055: 'Number search',
    k056: 'Enter a text filter option',
    k057: 'search',
    k058: 'Please select a meeting',
    k059: '乘车人姓名',
    k060: '请输入乘车人姓名',
    k061: '成本中心',
    k062: '请选择成本中心',
    k063: '审批人',
    k064: '请选择审批人',
    k065: '案件成本中心',
    k066: '请选择案件成本中心',
    k067: '请输入内容',
    k068: '去支付',
    k069: '我知道了',
    k070: '代人叫车',
    k071: '欢迎使用代叫车服务',
    k072: '请输入乘车人电话',
    k073: '乘车人姓名(选填)',
    k074: '确认',
    k075: '取消代叫'
  },
  contact: {
    k001: 'Please enter the emergency contact phone number',
    k002: 'Please enter validation code',
    k003: 'Send validation code',
    k004: 'Resend {{ seconds }} s',
    k005: 'For your safety, it is recommended to set an emergency contact in advance. Once you tap Quick Report to Police , the system will automatically notify your emergency contact.',
    k006: "Accompanying Guard: a value-added service for the safety warning of users' trips. SMS messages about the users' trips are sent to emergency contacts every 10 minutes during the trip. Each service charge is 2 CNY, which is combined with the car fare.",
    k007: 'For trip guardian service detail, please refer to value-added service page',
    k008: 'submit',
    k009: 'Please enter cell phone number',
    k010: 'Please enter the correct cell phone number',
    k011: 'Emergency contact number can not be the same as the current user phone number, please enter a different number',
    k012: 'Emergency',
    k013: 'Code',
    k014: 'Please enter the image verification code',
    k015: 'Send validation code fail'
  },
  coupons: {
    k001: 'Unused',
    k002: 'Usage Records',
    k003: 'Expired',
    k004: 'There is no related coupon information',
    k005: 'Usable when exceeds {{money}} Yuan',
    k006: 'enterprise ',
    k007: 'individual',
    k008: 'View Details',
    k009: 'Available Platforms',
    k010: 'Available Vehicle Types',
    k011: 'Available Cities',
    k012: 'There is unlimited except {{limit}}',
    k013: 'This coupon is frozen and can be reinstated if the travel rules are not met',
    k014: 'Usage details'
  },
  estimate: {
    k001: 'Journey estimated',
    k002: 'No car types available',
    k003: 'operation failed',
    k004: 'Last choice',
    k005: 'type',
    k006: 'Select all',
    k007: 'Estimated',
    k008: 'Call Concurrently',
    k009: 'estimated Fare is {{Yuan}} Yuan',
    k010: ' ',
    k011: 'Server error, please refresh the page and try again',
    k012: 'Real-time Ride',
    k013: 'The estimated price exceeds the limit for a single ride, please choose another car scenario',
    k014: 'The estimated price exceeds the limit of the scene quota, please select another car scene or contact the enterprise administrator',
    k015: 'Fare Estimation Illustration',
    k016: 'Dynamic Fare Increase',
    k017: 'close',
    k018: 'CNY',
    k019: 'car types have been selected',
    k020: 'times',
    k021: 'discounted',
    k022: 'zero',
    k023: 'min',
    k024: '',
    k025: 'KM',
    k026: 'meter',
    k027: 'Passenger\'s cell phone number',
    k028: 'Please enter your mobile number',
    k029: 'Please enter the correct cell phone number',
    k030: 'Confirm',
    k031: 'Passengers:',
    k032: 'Start requesting a ride',
    k033: 'Pricing rule',
    k034: 'Half-day pack(4h)',
    k035: 'Chartered bus(8h)',
    k036: 'Bus',
    k037: 'Package estimate',
    k038: 'Extra mileage',
    k039: 'Extra charge for overtime',
    k040: 'The estimated value is calculated based on real-time traffic conditions, time and other factors. There are fluctuations, and it is for reference only.',
    k041: 'This model cannot be cancelled',
    k042: 'Airport pick-up cannot be changed',
    k043: 'selected',
    k044: '个',
    k045: '已选以下 {{count}} 个服务商',
    k046: '请选择服务商',
    k047: '确定',
    k048: '一口价'
  },
  invoice: {
    k001: 'Request Time：',
    k002: 'CNY',
    k003: 'Departure：',
    k004: 'Dest：',
    k005: 'Passenger：',
    k006: 'No more',
    k007: 'Select all',
    k008: '{{count}} trips in total',
    k009: 'Next',
    k010: 'month：',
    k011: 'Total amount：',
    k012: 'Invoice amount',
    k013: '{{count}} trips',
    k014: 'To be invoiced/Not Invoiced',
    k015: 'Invoiced',
    k016: 'No order to be invoiced',
    k017: 'No order has been invoiced',
    k018: 'Please select orders to invoice',
    k019: 'Please confirm whether there are two payments for the same order. Please check the box together and go to invoice',
  },
  invoiceDetails: {
    k001: 'Invoice details',
    k002: 'Invoice type',
    k003: 'Invoice title (company name)',
    k004: 'Corporate Tax Id',
    k005: 'Invoice amount',
    k006: 'Submission time',
    k007: 'Receiving method',
    k008: 'Email address',
    k009: 'Please enter your email address',
    k010: 'Related trips',
    k011: 'Including {{count}} trips',
    k012: 'View',
    k013: 'If you have any questions about the invoice information, please call {{customerServiceNumber}} for consultation.',
    k014: 'Resend electronic invoice',
    k015: 'Please enter the correct email address',
    k016: 'Loading',
    k017: 'Operated successfully',
    k018: 'Bank of deposit',
    k019: 'address',
    k020: 'CNY'
  },
  login: {
    k001: 'Please enter cell phone number',
    k002: 'Please enter validation code',
    k003: 'Send validation code',
    k004: 'Log in',
    k005: 'Please enter the correct mobile phone number',
    k006: 'Failed to send verification code',
    k007: 'WeChat Service Account',
    k008: 'Please enter email',
    k009: 'Please enter the correct email address',
    k010: 'Switch to email login',
    k011: 'Switch to mobile login',
    k012: '请输入图片验证码',
  },
  noAuth: {
    k001: 'You do not have permission to call a car.',
    k002: 'Please contact the administrator or call the service hotline {{customerServiceTelephone}}',
    k003: 'Missing permissions.',
    k004: 'You have been disabled vehicle features, unblocked time is',
    k005: 'Contact the administrator or call the service hotline',
  },
  orderdetails: {
    k001: 'CNY',
    k002: 'End of trip, tap to pay the remaining amount.',
    k003: 'Prepaid successfully',
    k004: 'Change reason for using car',
    k005: 'Rate Trip',
    k006: 'Order number：',
    k007: 'Request Time：',
    k008: 'Miles：',
    k009: 'KM',
    k010: 'meter',
    k011: 'Duration：',
    k012: 'Type of car used：',
    k013: 'Vehicle information：',
    k014: 'Business payments：',
    k015: 'Personal payments：',
    k016: 'Fare Details',
    k017: 'If you have any questions, please call{{customerServiceTelephone}}',
    k018: 'Enterprise approval related',
    k019: 'Final approval status',
    k020: 'Time：',
    k021: 'Approver：',
    k022: 'Reasons for approval：',
    k023: 'Close',
    k024: 'Change reason for using car',
    k025: 'Submit',
    k026: 'cancel',
    k027: 'Please enter the reason for the trip (within 100 words)',
    k028: 'Note: Once the trip is approved, the reason for the trip can not be changed',
    k029: 'Service error, please refresh the page and try again',
    k030: 'Please enter the reason for the trip',
    k031: 'Submitted successfully',
    k032: 'Refund has been initiated for the remaining prepaid amount.',
    k033: 'Prepayment has been deducted',
    k034: 'This trip is beyond the standard',
    k035: 'Trip Scenarios：',
    k036: 'Scene code：',
    k037: 'This trip is out of standard, please confirm',
    k038: 'View instructions',
    k039: 'Put away instructions',
    k040: 'Scehdule Assistance requested another car for this trip and the fare difference has been deducted',
    k041: 'The difference between different platform models has been directly deducted and does not need to be paid by the enterprise',
    k042: 'Particularly satisfied',
    k043: 'Feel average',
    k044: 'Very bad',
    k045: 'Unpaid',
    k046: 'Paid',
    k047: 'CNY',
    k048: 'discounted',
    k049: 'Fare Details',
    k050: 'Prepaid details',
    k051: 'Evaluate this tour',
    k052: 'anonymity',
    k053: 'Complaint progress',
    k054: 'Submit a complaint',
    k055: 'Be dealing with',
    k056: 'Processing complete',
    k057: 'Trip Details',
    k058: 'The trip has been cancelled',
    k059: 'The order has been cancelled, you can take a new taxi.',
    k060: 'Take a new taxi',
    k061: 'Pay now',
    k062: 'Additional evaluation:',
    k063: 'Sorry for the inconvenience caused to your travel experience',
    k064: ',If necessary',
    k065: 'Click to complain',
    k066: 'Supplementary evaluation',
    k067: 'Enterprise approval related',
    k068: 'Historical approval',
    k069: 'Expand approval record',
    k070: 'The{{index}}th time',
    k071: 'Unfold',
    k072: 'Pack up',
    k073: 'Resubmit',
    k074: 'Reason for rejection:',
    k075: 'Failed to locate your position',
    k076: 'This trip is beyond the standard',
    k077: 'The business has paid:',
    k078: 'Individuals have paid:',
    k079: 'Individual to be paid:',
    k080: 'The trip has been cancelled,',
    k081: 'Please pay the withdrawal charge',
    k082: 'Generated fetch',
    k083: 'Personal payable',
    k084: 'Order total',
    k085: 'Order exception',
    k086: 'The value can be modified',
    k087: '',
    k088: '需自费',
    k089: '费用说明',
    k090: '此处显示金额仅供参考，具体金额以实际支付金额为准。',
    k091: 'Paid Successfully',
    k092: 'Resubmit:',
    k093: '结算中...',
    k094: '小调查 下车位置是否正确？',
    k095: 'no',
    k096: 'yes'
  },
  orderevaluate: {
    k001: 'Thank you for your praise, please share us to your colleagues',
    k002: "We're sorry for your unsatisfied trip experience, we will penalize the driver as appropriate",
    k003: 'Please enter your comment',
    k004: 'comment',
    k005: 'Give compliants',
    k006: 'Complaint progress',
    k007: 'processing',
    k008: 'Processed',
    k009: 'The driver will be dealt by the customer service team',
    k010: 'the platform will continue to improve on vehicle management',
    k011: 'Customer service is processing, please kindly wait...”',
    k012: 'Anonymous comments',
    k013: 'Please rate.',
    k014: 'Please enter the comment',
    k015: 'You have commented',
    k016: 'Please select the rating TAB'
  },
  orders: {
    k001: 'Waiting for the driver to take the order',
    k002: 'Waiting for the driver',
    k003: 'The driver has arrived',
    k004: 'Ongoing',
    k005: 'completed',
    k006: 'Completed',
    k007: 'Canceled',
    k008: 'Real-time',
    k009: 'Scheduled',
    k010: 'Airport',
    k011: 'Prepaid',
    k012: 'Blocked Amount',
    k013: 'Location：',
    k014: 'Request Time：',
    k015: 'Departure：',
    k016: 'Dest：',
    k017: 'To be invoiced/Not Invoiced',
    k018: 'Invoiced',
    k019: 'No more',
    k020: 'No trip',
    k021: 'Month：',
    k022: 'Total amount：',
    k023: 'Loading',
    k024: 'Unknown status',
    k025: 'Train Pickup',
    k026: 'Status:',
    k027: 'Unpaid',
    k028: 'Go to invoice',
    k029: 'Order exception',
    k030: 'Pending approval',
    k031: 'Chartered bus',
    k032: 'Please contact the platform online customer service to apply for invoicing',
    k033: 'Cancellation fee',
    k034: 'Note: When the approval status is passed, the purpose of using the car cannot be changed',
    k035: 'total of',
    k036: 'CNY'
  },
  payments: {
    k001: 'Unpaid',
    k002: 'Paid',
    k003: 'Select all',
    k004: 'Pay',
    k005: 'There is no individual paid orders',
    k006: 'There is no paid order this month',
    k007: 'month：',
    k008: 'Total amount：',
    k009: 'CNY',
    k010: 'Please confirm that you have completed the payment',
    k011: 'There is no payment result, if you have already paid, please contact customer service',
    k012: 'Payment failed',
    k013: 'Got it!',
    k014: 'Loading',
    k015: 'Please select a order to pay',
    k016: 'Order total:',
    k017: 'reimbursement',
    k018: 'Prepaid',
    k019: 'The order has been rejected, please update the reason for the trip and resubmit',
    k020: 'Request Time:',
    k021: 'Departure:',
    k022: 'Dest:',
    k023: 'city:',
    k024: 'Payment turnover.',
    k025: '{{count}} trips in total',
    k026: 'prepayment',
    k027: 'In which {{ money }} yuan has been returned.',
    k028: 'Failed to obtain {{appid}} payment connection',
    k029: 'Credit for expenses',
    k030: 'Please select payment order'
  },
  perApply: {
    k001: 'Available locations',
    k002: 'Available cities',
    k003: 'Application Rules',
    k004_1: '1. You need to enter a planned pick-up location and destination, after the application is appproved,',
    k004_2: 'you can choose a pick-up location within the radius {{beginDistance}}m of the center of the planned pick-up location',
    k004_3: 'You can choose a pick-up location within the planned city',
    k004_4: 'Enter destination within the radius {{endDistance}}m of the center of the planned destination to request trip',
    k004_5: 'Enter destination within the planned city',
    k005: '2.You can apply up to {{useCarTimeSpan}} days to request trips, accurate to minutes. You will be unable to request after expiration',
    k006: '5.Each employee can submit multiple applications at the same time',
    k007: 'List of application forms',
    k008: 'Trip Scenario',
    k009: 'Please select the trip scenario.',
    k010: 'Starting point mode',
    k011: 'pick-up location',
    k012: 'Please select a pick-up location',
    k013: 'Use car starting city',
    k014: 'Select starting city',
    k015: 'destination mode',
    k016: 'Use vehicle destination',
    k017: 'Enter destination',
    k018: 'Destination city',
    k019: 'Select destination city',
    k020: 'Request start time',
    k021: 'select year / month / day hour: minute',
    k022: 'Select start time',
    k023: 'Request end time',
    k024: 'Select start time',
    k025: 'Select end time',
    k026: 'Number of Trips',
    k027: 'Please enter the number of trips',
    k028: 'Amounts',
    k029: 'Please enter trip amount',
    k030: 'Reason for Application',
    k031: 'Please enter the reason for your application',
    k032: 'Submit Application',
    k033: 'return',
    k034: 'Submit Successfully',
    k035: 'Submit failed.',
    k036: 'Please fill in the complete information and try again.',
    k037: 'After returning now, the edited content will not be saved.',
    k038: 'Please fill in the application reason.',
    k039: 'No available pre-trip approval scenario',
    k040: '3.The maximum amount of a single application is unlimited.',
    k040_1: '3.The maximum amount of a single application is {{useCarMaxMoney}} yuan, which exceeds the amount of the application cannot be initiated.',
    k041: '4.The maximum number of times for a single application is one. If the application exceeds the number of usage, the application cannot be initiated.',
    k041_1: '4.The maximum number of times for a single application is {{useCarMaxTimes}}. If the application exceeds the number of usage, the application cannot be initiated.',
  },
  perApplyRecord: {
    k001: 'All',
    k002: 'Unused',
    k003: 'in use',
    k004: 'Used',
    k005: 'Expired',
    k006: 'Pending approval',
    k007: 'Pending',
    k008: 'Agreed',
    k009: 'Rejected',
    k010: 'No record yet',
    k011: 'Application status:',
    k012: 'Approval status:',
    k013: 'Service error, query failed',
    k014: 'Loading',
    k015: 'Available amount：{{canUseMoney}}CNY',
    k016: 'Available times：{{canUseCount}}second'
  },
  prepaidProgress: {
    k001: 'Prepaid policy',
    k002: 'When the personal payment exceeds xx CNY,  user needs to pay this amount in advance',
    k003: 'Personal pay portion of the fare, if exceeds the total amount at the end the trip, the difference will be revert back to you with the same payment method',
    k004: 'Payment turnover.',
    k005: 'Copy succeeded.',
    k006: 'copy',
    k007: 'Prepaid fare {{payMoney}} CNY',
    k008: 'Prepaid payment had been deducted {{money}} Yuan',
    k009: 'The remaining {{money}} CNY has been refunded',
    k010: 'Process result',
    k011: 'platform has accepted refund application',
    k012: 'You remaining fund is in the process of reverting back to your account. You should have the fund back in 3 work-days',
    k013: 'Contact customer service',
  },
  register: {
    k001: 'please enter the name of your company',
    k002: 'please enter your real name',
    k003: 'please enter your mobile phone number',
    k004: 'please enter the verification code',
    k005: 'I have read and agreed to the',
    k006: '<User Instructions>',
    k007: 'and agree',
    k008: 'Get verification code',
    k009: 'Submit'
  },
  report: {
    k001: 'Trip Scenarios：',
    k002: 'city',
    k003: 'Ride Type',
    k004: 'Start calculating',
    k005: 'Calculation completed',
    k006: 'Number of orders and total payment amount',
    k007: 'All',
    k008: 'No content yet',
    k009: 'Trip Scenarios and related amount',
    k010: 'Vehicle type and related amount',
    k011: 'Amount',
    k012: 'Order',
    k013: 'Order',
    k014: 'Amount(CNY)',
    k015: 'Number of orders',
    k016: 'The end time cannot be earlier than the start time'
  },
  runing: {
    k001: 'Trip canceled',
    k002: 'Trip completed',
    k003: 'The order does not exist or the order has been completed',
    k004: 'Service error, please refresh the page and try again',
    k005: 'Are you sure to cancel the trip?',
    k006: 'We are looking for the driver for you, please wait a minute. ',
    k007: 'Cancel Trip',
    k008: 'Keep wait',
    k009: "display driver's location near the departure time.",
    k010: 'KM',
    k011: 'meter',
    k012: 'Waited',
    k013: 'Keep requesting your trip...',
    k014: 'Loading',
    k015: 'Include{{carSource}}-{{carLevelName}}class, {{carLength}}car types in total, estimate fare {{price}} CNY',
    k016: 'Requesting {{carLength}} car types',
    k017: 'class',
    k018: 'Estimated',
    k019: 'Add more types get on faster',
    k020: 'Select all',
    k021: 'Append call',
    k022: 'vehicle ',
    k023: 'Call Concurrently',
    k024: 'Distance',
    k025: 'expected to arrive in',
    k026: 'minutes.',
    k027: 'Premier Airport Pickup',
    k028: "Driver's profile photo",
    k029: '',
    k030: 'Driver refuses to carry the passenger.',
    k031: 'Driver is too far away.',
    k032: 'Car Information is not consistent.',
    k033: 'Other reasons',
    k034: 'Continue the Trip',
    k035: 'Modify destination.',
    k036: 'Call 110',
    k037: 'Share',
    k038: 'Reasons for the trip',
    k039: 'Rate Trip',
    k040: 'operation failed',
    k041: 'Failed to get the real-time location of the vehicle, please refresh the page and try again.',
    k042: 'Cancel',
    k043: 'Modify address.',
    k044: 'OK',
    k045: 'Are you sure to change the destination?',
    k046: 'Change the destination to',
    k047: 'the estimated fare will be',
    k048: 'Destination changed successfully!',
    k049: 'submit',
    k050: 'Reason For Using The Vehicle',
    k051: 'Share my trip',
    k052: 'To',
    k053: 'second',
    k054: 'The driver has been waiting for',
    k055: 'minutes.',
    k056: "Please select the canceling reason",
    k057: 'Premier airport pick-up & guidance service ',
    k058: 'Premier airport pick-up service staff has been assigned, will send you the contact information later',
    k059: 'Cancel the service',
    k060: 'Serial number',
    k061: 'You will be greeted at the airport arrivals',
    k062: 'Contact premier airport pick-up service staff',
    k063: "Can't contact the premier airport pick-up service staff at this moment",
    k064: 'Poor service attitude of the airport pick-up service staff',
    k065: 'The airport pick-up service staff requested me to cancel',
    k066: 'If the information was incorrect, placing a new order',
    k067: "Don't need a premier airport pick-up service now",
    k068: 'close',
    k069: 'The order has been taken',
    k070: 'CNY',
    k071: 'No car type',
    k072: 'car types',
    k073: 'Personal payment exceeds prepaid amount by {{prepaidAmount}} CNY, please pay to add car types',
    k074: ' ',
    k075: 'Premier airport pick-up & guidance profile photo',
    k076: 'Customer Service',
    k077: 'We are assigning you a',
    k078: 'Please select car types',
    k079: 'Add car types',
    k080: 'Keep wait',
    k081: 'No available cars nearby, ',
    k082: 'would you like to add more car types to get quicker response?',
    k083: 'Order has been cancelled',
    k084: 'System Prompt',
    k085: 'Your order has been reassigned by the platform, is reordering for you?',
    k086: 'Cancel',
    k087: 'Confirm',
    k088: 'Cancel Schedule',
    k089: 'Please keep patient',
    k090: 'Sure you want to cancel the trip?',
    k091: 'Schedule Assistance service has started',
    k092: 'Matching you with nearby drivers...',
    k093: 'Expected to find a driver soon',
    k094: 'Please keep patient',
    k095: 'If you have any questions, please call the service hotline at ',
    k096: 'Depart at {{hourEn}} on {{timeEn}}',
    k097: 'Expected to find a driver for you 30 minutes before departure',
    k098: 'Sorry, due to the shortage of nearby models, there is no driver answer',
    k099: 'Customer service will contact you to advise you to reorder the car',
    k0100: 'If you have any questions, please call our service hotline:',
    k0101: 'Resend Request',
    k0102: 'Please inform the driver that the last number is',
    k0103: 'Trip booking failed',
    k0104: 'Sorry, the trip reservation failed',
    k0105: 'The reservation manager is on the call for you...',
    k0106: 'In-app forwarding',
    k0107: 'Choose how to share',
    k0108: 'Copy the link to your friend',
    k0109: 'The bill is expected to reduce carbon emissions by {{carbonEmission}}g',
    k0110: 'System Prompt',
    k0111: 'The order has been cancelled by the platform',
    k0112: 'Got it',
    k0113: 'Your cancellation request has been sent, please be patient ~',
    k0114: 'Customer service staff is processing, after the reservation is successful, the system will automatically push the driver and other information, please pay attention to check!',
    k0115: 'Trip Details',
    k0116: 'Departure',
    k0117: 'Dest',
    k0118: 'Contact number',
    k0119: 'Vehicle type',
    k0120: 'Booking type',
    k0121: 'Use time',
    k0122: 'Half-day pack',
    k0123: 'full-time',
    k0124: 'New energy models are being selected for you first',
    k0125: 'A few seconds of waiting is also a gesture of environmental protection',
    k0126: 'intelligent dispatch',
    k0127: 'distance first',
    k0128: 'faster response',
    k0129: 'low price first',
    k0130: 'driver, please wait a moment',
    k0131: 'Intelligent dispatch',
    k0132: 'Distance first driver',
    k0133: 'Faster response precedence',
    k0134: 'Low price first',
    k0135: 'Please select dispatch mode',
    k0136: 'The current order is a relay order, the driver is about to finish the last order, please wait patiently',
    k0137: 'Few drivers nearby, there are suitable drivers to take orders',
    k0138: 'Whether to cancel the order?',
    k0139: 'The current driver\'s side signal is weak, the position update is delayed, please wait.',
    k0140: 'Platform does not support three-party call, please use the mobile phone number to make a call.',
    k0141: 'Please be patient, cancellation fees may apply if the tour is cancelled closer to the scheduled time.',
    k0142: 'Modification failed',
    k0143: 'The platform does not currently support the modification of this destination.',
    k0144: 'Continue using car',
    k0145: 'Please choose or enter a reason',
    k0146: 'Submit',
    k0147: 'Please enter other reasons',
    k0148: 'Respond faster, get in the car quicker!',
    k0149: "It's peak time, fewer drivers available.",
    k0150: 'Few drivers nearby. Add a',
    k0151: '-yuan fee to attract more drivers quickly.',
    k0152: 'Confirm Addition',
    k0153: "wait",
    k0154: 'Cancelling',
  },
  runingOrders: {
    k001: 'Departure:',
    k002: 'Dest:',
    k003: 'Passenger:',
    k004: 'There is no on-going trips',
    k005: 'Passenger\'s phone:',
    k006: 'License plate number:'
  },
  scenes: {
    k001: 'No trip scenario available',
    k002: 'Available Balance',
    k003: 'CNY',
    k004: 'unlimited',
    k005: 'Return after approval',
    k006: 'Available Vehicle Types',
    k007: 'View Details',
    k008: 'Available date:',
    k009: 'unlimited',
    k010: 'To',
    k011: 'Available time:',
    k012: 'Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday',
    k013: 'Working day',
    k014: 'Weekend & Holidays',
    k015: 'Single trip limit',
    k016: 'City',
    k017: 'Location',
    k018: 'Single trip',
    k019: 'Round trip',
    k020: 'Anywhere',
    k021: 'Manager',
    k022: 'Economy class',
    k023: 'Comfort class',
    k024: 'Business class',
    k025: 'Luxury class',
    k026: 'Loading',
    k027: 'Available cities:',
    k028: 'Available locations:',
    k029: 'Available Modes:',
    k030: 'Available models:',
    k031: 'Intercity vehicles:',
    k032: 'search',
    k033: 'Total available limit',
    k034: 'Please enter the scene code or name',
    k035: '*Meet any of the above use restrictions',
    k036: 'Trip Scenario Rules',
    k037: 'No limit',
    k038: 'Allow',
    k039: 'None',
    k040: 'No limit',
    k041: 'Weekends and public holidays',
    k042: 'No available time period',
    k043: 'Forbid',
    k044: 'The following day'
  },
  setphone: {
    k001: 'cell-phone number',
    k002: 'Set the mobile phone',
    k003: 'Please enter validation code',
    k004: 'Send validation code',
    k005: 'Sent',
    k006: "The cell phone number entered will be the default passenger's cell phone number",
    k007: 'submit',
    k008: 'Please enter cell phone number',
    k009: 'Please enter the correct cell phone number',
    k010: 'Please enter the image verification code',
    k011: 'Send validation code fail'
  },
  settings: {
    k001: 'Passenger',
    k002: 'Approver',
    k003: 'cell-phone number',
    k004: 'No set',
    k005: 'Trip Type',
    k006: 'Emergency contact',
    k007: 'Language',
    k008: 'OK',
    k009: 'Smart Mode',
    k010: 'Self-Select Mode',
    k011: 'Chinese',
    k012: 'Please enter cell phone number',
    k013: 'Loading',
    k014: 'Saved successfully',
    k015: 'Engilish',
    k016: 'Please enter',
    k017: "Please set the user's cell phone number first",
    k018: 'Frequently-usesd Address',
    k019: 'To set',
    k020: 'Import address book',
    k021: 'Have been imported',
    k022: 'Not imported',
    k023: 'Please import the address book type file',
    k024: 'Import successfully',
    k025: 'Import failure',
    k026: 'Didi audio recording permission',
  },
  share: {
    k001: 'Share my trip',
    k002: 'Tap the  top right-hand corner to share the trip information with your family and friends',
    k003: 'License plate number',
    k004: 'Departure',
    k005: 'Dest',
    k006: 'Time'
  },
  trip: {
    k001: 'Departure：',
    k002: 'Dest：',
    k003: 'CNY'
  },
  waitingShenzhou: {
    k001: "We are processing your trip, after successfully reserving your trip you will receivce the car and the driver's information, please note to check!",
    k002: 'Trip Details',
    k003: 'Departure',
    k004: 'Dest',
    k005: 'Contact number',
    k006: 'Ride Type',
    k007: 'Request Time',
    k008: 'Premier Airport Pickup',
    k009: 'Customer service number',
    k010: 'Trip cancellation',
    k011: 'fixed-line telephone',
    k012: 'Are you sure to cancel the trip?',
    k013: "We're trying to request your trip, please wait a little longer",
    k014: 'Keep wait'
  },
  writeInvoice: {
    k001: 'Invoice title type',
    k002: 'Enterprises',
    k003: 'Company name',
    k004: 'Fill in the name of the company for the invoice',
    k005: 'Corporate Tax Id',
    k006: 'Taxpayer identification number',
    k007: 'Fill in bank of deposit and account number',
    k008: 'address',
    k009: 'Fill in the address and telephone number',
    k010: 'Total amount',
    k011: 'email',
    k012: 'Please enter your email address',
    k013: 'Please enter a comment',
    k014: 'Confirm the invoice information',
    k015: 'Invoice type',
    k016: 'Electronic invoice',
    k017: 'Email address',
    k018: 'Ok',
    k019: 'submit',
    k020: 'Loading',
    k021: 'Invoice issued successfully',
    k022: 'Invoice issued successfully, will be sent to the confirmed email address in Two working days',
    k023: 'Invoice issued fail',
    k024: 'close',
    k025: 'Invoicing failed, please try again later',
    k026: 'System abnormal',
    k027: 'Please enter the invoice title (company name)',
    k028: 'Please enter your email address',
    k029: 'Please enter the correct email address',
    k030: 'Bank of deposit'
  },
  preApproval: {
    k001: 'Apply for Trips',
    k002: 'Please select the application form',
    k003: 'Click to view',
    k004: 'No application form',
    k005: 'Application form',
    k006: 'Please select',
    k007: 'Pending',
    k008: 'Agreed',
    k009: 'Rejected',
    k010: 'Resubmit',
    k011: 'Reason for rejection:',
    k012: 'View approval records',
    k013: 'Application number:',
    k014: 'Pre-trip Approval',
    k015: 'Applicant：',
    k016: 'pick-up location：',
    k017: 'Dest：',
    k018: 'Reason for application：',
    k019: 'Loading',
    k020: 'loading failed',
    k021: 'Pending approval',
    k022: 'Available time：',
    k023: 'Trip Scenarios：',
    k024: 'There is no record.',
    k025: 'Approved',
    k026: 'Select all',
    k027: 'reject',
    k028: 'pass',
    k029: 'Service error, query failed',
    k030: 'Please select at least one record first',
    k031: 'Please enter the rejection reason',
    k032: 'Operation successful',
    k033: 'Approval failed',
    k034: 'pick-up city：',
    k035: 'dest city：',
    k036: 'Amount available:',
    k037: 'Available times:',
    k038: ' CNY',
    k039: ' times',
  },
  prepaid: {
    k001: 'Paid Successfully',
    k002: 'Prepaid fare',
    k003: 'Remaining payment time',
    k004: 'Please pay the estimated price in advance. After the order is completed, if the actual amount is less than the estimated price you paid, the excess will be returned.',
    k005: 'Payment item',
    k006: 'Prepaid fare',
    k007: 'payment methods',
    k008: 'Wechat payment',
    k009: 'Alipay payment',
    k010: 'View orders',
    k011: 'Pay now',
    k012: 'Payment timeout',
    k013: 'Timeout, please pay again',
    k014: 'Back to homepage',
    k015: 'Loading ...',
    k016: 'Payment failed',
    k017: 'Please confirm whether you have completed payment.',
    k018: 'Not paid',
    k019: 'Paid',
    k020: 'There is no payment result, if you have already paid, please contact customer service',
    k021: 'Got it!',
    k022: 'Timeout, please pay again, relevant amount will be returned in 3 working days',
    k023: 'Being requested',
    k024: 'Jingdong Pay',
    k025: 'Payment confirmation',
  },
  title: {
    k001: 'Please select car types',
    k002: 'Confirm Trip',
    k003: 'Enterprise Travel Terms',
    k004: 'Informed consent form',
    k005: 'Prepayment',
    k006: 'Prepayment progress',
    k007: 'Select a coupon',
    k008: 'You have requested an reservation',
    k009: 'Waiting for a response',
    k010: 'Quick Report to Police',
    k011: 'Ongoing Orders',
    k012: 'Trip Scene',
    k013: 'Coupons',
    k014: 'Share trips',
    k015: 'Individual',
    k016: 'Enterprise account',
    k017: 'Enterprise recharge',
    k018: 'My Trips',
    k019: 'Trip Details',
    k020: 'Rate Trip',
    k021: 'Invoice',
    k022: 'Issue electronic invoice',
    k023: 'Invoice details',
    k024: 'Included trips',
    k025: 'Approval',
    k026: 'Approval details',
    k027: 'Personal Settings',
    k028: 'Emergency contact',
    k029: 'Car report',
    k030: 'Missing permissions.',
    k031: 'Sorry, something went wrong',
    k032: 'Debugging information',
    k033: 'Share trips',
    k034: 'Software usage protocol',
    k035: 'Log in',
    k036: 'Register',
    k037: 'Change trip application',
    k038: 'Apply for Trips',
    k039: 'Application Records',
    k040: 'Pre-trip Approval',
    k041: 'Hello, where are you going?',
    k042: 'Please select the trip scenario.',
    k043: 'Scenario Details',
    k044: 'Home',
    k045: 'Common address',
    k046: 'Cost center',
    k047: 'Meeting information supplement',
    k048: 'Tencent Map Test',
    k049: 'Carbon integrated panel',
    k050: 'interpage',
    k051: 'payment center',
    k052: 'No car permission',
    k053: 'My meeting',
    k054: 'Select meeting',
    k055: 'Fee breakdown',
    k056: 'Customer service center',
    k057: 'Complaint progress details',
    k058: 'Abnormal order confirmation'
  },
  habitAddress: {
    k001: 'Common address 1',
    k002: 'Common address 2',
    k003: 'Clear the address',
    k004: 'cancel',
    k005: 'Confirm',
    k006: 'Confirm to clear the configuration of "frequently used address 1"?',
    k007: 'Confirm to clear the configuration of "frequently used address 2"?',
    k008: 'Please select a specific address',
    k009: 'Frequently-usesd Address',
    k010: 'To set up'
  },
  common: {
    k001: 'Cancel',
    k002: 'Confirm',
    k003: 'Got it',
    k004:'System Prompt',
    k005:'Close',
    k006:'Show details',
    k007: 'Go to Pay'
  },
  serviceCenter: {
    k001: 'Item missing',
    k002: 'Please describe the missing item.',
    k003: 'Cost problem',
    k004: 'Please describe the problem you encountered',
    k005: 'Service problem',
    k006: 'Vehicle information',
    k007: 'Unpaid',
    k008: 'Problem type',
    k009: 'Submit',
    k010: 'Contact customer service:',
    k011: 'Submit Successfully',
    k012: 'Please describe the lost items.'
  },
  expenseDetails: {
    k001: 'Total amount',
    k002: 'CNY',
    k003: 'taxi-hailing',
    k004: 'Make an appointment with a butler to order the difference between different platform models for you has been directly deducted, and you do not need to pay.',
    k005: 'Have questions about fees?',
    k006: 'Click feedback',
    k007: '※ 若发生退款,部分费用可能将重新计算,请以实际退款金额为准。'
  },
  complaintsDetails: {
    k001: 'Submit a complaint',
    k002: 'Be dealing with',
    k003: 'Processing complete',
    k004: 'Vehicle information',
    k005: 'Unpaid',
    k006: 'Complaint details',
    k007: 'Contact customer service:'
  },
  orderAbnormal: {
    k001: 'This trip needs to confirm the abnormal situation, please fill in the description below and submit.',
    k002: 'This trip is out of standard, please confirm',
    k003: 'Normal',
    k004: 'Please fill in the cause of the anomaly, so that the approver can understand the situation. (within 200 words)',
    k005: 'Have questions about itinerary anomalies?',
    k006: 'Click to complain',
    k007: 'Submit',
    k008: 'Submit Successfully'
  },
  special: {
    k001: 'Please enter the cost center number or name',
    k002: 'Confirm joining the cost center?',
    k003: 'name:',
    k004: 'submit',
    k005: 'encoding:',
    k006: 'Source:',
    k007: 'delete',
    k008: 'No data available',
    k009: 'new',
    k010: 'Confirm to delete this cost center?',
    k011: 'Cumulative emission reduction',
    k012: 'Monthly emission reduction',
    k013: 'Cumulative number of low-carbon travel orders',
    k014: 'The number of low-carbon travel orders in the month',
    k015: 'Ranking',
    k016: 'Section',
    k017: 'Emission reduction',
    k018: 'Order share',
    k019: 'The start time cannot be later than the end time',
  },
  meeting: {
    k001: 'Conference information',
    k002: 'Click to select meeting information',
    k003: 'Cost center information',
    k004: 'Click to select Cost center information',
    k005: 'Click to select PO information',
    k006: 'PO information',
    k007: 'Budgeted amount',
    k008: 'The page displays the cost center corresponding to the conference RAE, and the taxi fee will be settled in the Amgen taxi PO of the corresponding region, please be informed.',
    k009: 'Please enter the PO code',
    k010: 'Please enter the cost center information code',
    k011: 'Please enter the meeting information number or name',
    k012: 'Please select a cost center',
    k013: 'Please select the PO information',
  },
  menu: {
    k001: 'Please contact the platform online customer service to apply for invoicing',
    k002: 'Control console',
    k003: 'My meeting',
    k004: 'The enterprise has shut down the current service',
    k005: 'Book train tickets'
  },
  sanofiMeeting: {
    k001: 'Add IO',
    k002: 'One-click delete',
    k003: 'No meeting',
    k004: 'Deadline date',
    k005: 'Expand more',
    k006: 'Pack up',
    k007: 'Do you want to delete all meetings?',
    k008: 'Successfully deleted',
    k009: 'The meeting cannot be deleted',
    k010: 'Do you want to delete the meeting?',
    k011: 'Please enter the meeting ID or name',
    k012: 'Confirm to add?',
    k013: 'Successful operation',
    k014: 'IOName',
    k015: 'IOCode',
    k016: 'Owner',
    k017: 'ProductCode',
    k018: 'Meeting cannot be deleted'
  }
}