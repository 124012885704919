import React from 'react';
import style from './smartCarTypeChoose.scss';
import { inject, observer } from 'mobx-react';
import { Dialog, Toast, Modal, Popup, Button, Input, Image } from 'antd-mobile';
import { RightOutline, ExclamationTriangleOutline } from 'antd-mobile-icons';

import webService from './../../util/webService';
import {
  generateConfirmDependency,
  generatePlaceOrder,
} from './../../util/paramsHelper';

import DispatchMode from './dispatchMode';

import {
  debounce,
  stringToBase64,
  exceptThisSymbols,
  checkPhone,
} from './../../util/utils';
import { ES_JieJiLiBin } from './../../util/config';
import gc from './../../util/globalConfiguration';
import images from '../../util/images';
import loger from './../../util/loger';
import MessageBox from './messageBox';
import { ForgeRouter } from './../../util/utils';

@inject('globalStore', 'mainStore', 'estimateStore', 'confirmStore')
@observer
class SmartCarTypeChoose extends React.Component {
  state = {
    activeLevel: 1,
    lockLevel: [],
    cars: [],
    smartLabelCar: [],
    smartCheckAll: false,
    labelCode: '',
    priceRules: {}, // 默认计价规则
    scene: {},
    currenCheckedDetails: {
      count: 0,
      detail: '',
      leftPrice: 0,
      rightPrice: 0,
      list: [],
    },
    carLabels: [],
    lastChooseCar: [],
    visible: false,
    showPhone: false,
    inner_phone: '',
    showMode: false,
    dispatchModeList: [],
    isScrolling:false
  };
  sectionRefs = [];
  smartRefs = [];
  contentRef = null
  globalStore = this.props.globalStore;
  mainStore = this.props.mainStore;
  estimateStore = this.props.estimateStore;
  confirmStore = this.props.confirmStore;

  t = this.props.t;

  dispatchModeRef = undefined;

  // 企业支付比例默认展示
  get isPaymentRatio() {
    if (this.globalStore.userInfo.permissions) {
      return this.globalStore.userInfo.permissions.estimate_paymentRatio_hide
        ? true
        : false;
    }
    return false;
  }

  // 派单类型默认 智能派单
  get modeTypeInfo() {
    switch (this.estimateStore.carMode) {
      case 1:
        return {
          value: this.t('runing.k0131'),
          img: images.brainPower,
        };
      case 2:
        return {
          value: this.t('runing.k0132'),
          img: images.distanceFirst,
        };
      case 4:
        return {
          value: this.t('runing.k0133'),
          img: images.responsePriority,
        };
      case 3:
        return {
          value: this.t('runing.k0134'),
          img: images.lowPrice,
        };
      default:
        return {
          value: this.t('runing.k0131'),
          img: images.brainPower,
        };
    }
  }

  // 判断样式展示
  get paddingStyleTop() {
    if (
      gc.process.skipConfirmationPage &&
      gc.process.estimatePageShowPhoneInput &&
      this.globalStore.userInfo.permissions.setting_phone
    ) {
      return true;
    }
    if (this.estimateStore.carMode > 0) {
      return true;
    }
    return false;
  }

  get isLanguage() {
    return this.globalStore.userInfo.language === 1 ? true : false;
  }

  render() {
    return (
      <div className={style.container}>
        <MessageBox
          ref={(dom) => {
            this.messageBoxRef = dom;
          }}
          history={this.props.history}
        ></MessageBox>
        <div className={style.car_box}>
          <div
            className={
              this.mainStore.serverType === 20
                ? style.carlevel_chartered
                : style.carlevel
            }
          >
            {/* 标签左侧导航 */}
            {this.state.carLabels.map((item, index) => {
              return (
                <div
                  className={
                    this.state.activeLevel === item.dynamicCode
                      ? `${
                          this.props.language === 1
                            ? style.level
                            : style.level_en
                        } ${style.active}`
                      : this.props.language === 1
                      ? style.level
                      : style.level_en
                  }
                  key={index}
                  onClick={() => {
                    this.setState({ activeLevel: item.dynamicCode, isScrolling:true });
                    // 延时后重置标志并更新状态
                    setTimeout(() => {
                      this.setState({isScrolling: false})
                    }, 800); // 根据实际情况调整延时
                    const element = this.smartRefs[index].dom;
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  <div className={style.name}>
                    {this.isLanguage ? item.nameCn : item.nameEn}
                    {item.sideBarCount ? <span>{item.sideBarCount}</span> : <></>}
                  </div>
                  <div className={style.underline}></div>
                </div>
              );
            })}
            {/* 车型左侧导航 */}
            {this.state.cars.map((item, index) => {
              return (
                <div
                  className={
                    this.state.activeLevel === item.id
                      ? `${
                          this.props.language === 1
                            ? style.level
                            : style.level_en
                        } ${style.active}`
                      : this.props.language === 1
                      ? style.level
                      : style.level_en
                  }
                  key={index}
                  onClick={() => {
                    this.setState({ activeLevel:  item.id, isScrolling:true });
                    // 延时后重置标志并更新状态
                    setTimeout(() => {
                      this.setState({isScrolling:false})
                    }, 800); // 根据实际情况调整延时
                    const element = this.sectionRefs[index].dom;
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  <div className={style.name}>
                    {this.isLanguage ? item.nameCn : item.nameEn}
                    {item.checkCount > 0 && this.mainStore.serverType !== 20 ? (
                      <span>{item.checkCount}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* {((item.reimbursementCn && item.reimbursementCn.indexOf('报销0')  === 0) && (oauthType === '15')) || this.isPaymentRatio ? <></> : <div className={style.tip}>{this.isLanguage ? item.reimbursementCn : item.reimbursementEn}</div>} */}
                  <div className={style.underline}></div>
                </div>
              );
            })}
            {
              this.state.isScrolling ? <div className={style.carlevel_mask}></div> : <></>
            }
          </div>
          <div className={style.cars_wrap}>
          <div className={style.cars} onScroll={this.handleScroll} ref={(ref) => (this.contentRef = ref)}>
            {/* 标签右侧预估 */}
            {this.state.carLabels.map((item, index) => {
              return (
                <div
                  className={`${style.car_smart} ${index === this.state.carLabels.length - 1 ? style.car_smart_border_bottom : style.car_smart_padding_top}`}
                  key={index}
                  ref={(dom) => {
                    this.smartRefs[index] = {dom: dom, id:item.dynamicCode};
                  }}
                >
                  {item.data.map((e, i) => {
                    return (
                      <div
                      key={i}
                        className={`${style.car_type} ${
                          i === item.data.length - 1 ? '' : style.car_bottom
                        }`}
                      >
                        <div className={style.left}>
                          <span className={style.car_source}>
                            {e.labelName}
                          </span>
                          <span className={style.car_desc}>{e.labelDesc}</span>
                          <div className={style.car_select} onClick={() => this.showCarSmart(e, i)}>
                          {this.t('estimate.k043')}&nbsp;
                            <span className={style.car_count}>{e.checkCount}/{e.carNum}</span>&nbsp;{this.t('estimate.k044')}
                            <RightOutline color="#9DA3AB" fontSize={12} />
                          </div>
                        </div>
                        <div className={style.right}>
                          <div className={style.car_estimate}>
                          {this.t('estimate.k007')}&nbsp;<span>{e.priceLab}</span>&nbsp;{this.t('estimate.k018')}
                          </div>
                          <div
                            onClick={() => {
                              this.checkSmartLevelAll(item, e);
                            }}
                            className={
                              e.checked ? `${style.cbx} ${style.checked}` : style.cbx
                            }
                          >
                            <div></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {/* 车型右侧预估 */}
            {this.state.cars.map((item, index) => {
              return (
                <div
                  className={style.carpannel}
                  key={item.id}
                  ref={(dom) => {
                    this.sectionRefs[index] = {dom: dom, id: item.id};
                  }}
                >
                  <div className={style.head}>
                    <div>
                      {item.nameCn}
                      {this.t('estimate.k005')}
                      {item.deductionCn && item.deductionCn.length > 0 ? (
                        item.deductionCn.map((val) => {
                          return (
                            <span className={style.deduction_key}>
                              {val.key.txt}
                              <span
                                className={style.deduction_value}
                                style={{ color: val.value.color }}
                              >
                                {val.value.txt}
                              </span>
                            </span>
                          );
                        })
                      ) : (
                        <></>
                      )}

                      {
                        // k0144: '计价规则'
                        this.mainStore.serverType === 20 ? (
                          <span
                            className={style.valuation}
                            onClick={() => {
                              this.loadRules(item);
                            }}
                          >
                            {this.t('main.k0144')}
                            <RightOutline color="#999999" fontSize={12} />
                          </span>
                        ) : (
                          <></>
                        )
                      }
                    </div>
                    {this.mainStore.serverType === 20 ? (
                      <span className={style.rightness}>{item.number}</span>
                    ) : (
                      <>
                        <div>{this.t('estimate.k006')}</div>
                        <div
                          className={
                            item.checkAll
                              ? `${style.cbx} ${style.checked}`
                              : style.cbx
                          }
                        >
                          <div
                            onClick={() => {
                              this.checkLevelAll(item);
                            }}
                          ></div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className={style.carlist}>
                    {item.list.map((car) => {
                      return (
                        <div className={style.car} key={car.dynamicCode}>
                          <div>
                            <img src={car?.carSourceImg} alt="" />
                          </div>
                          <div>
                            <div>{car.carSource}</div>
                          </div>
                          <div>
                            <div>
                              {!car.isFixed ? this.t('estimate.k007') : this.t('estimate.k048')}
                              <span>{car.estimatePrice}</span>
                              {this.t('estimate.k018')}
                            </div>
                            {car.couponAmount ? (
                              <div>
                                {this.t('estimate.k021')}
                                <span>{car.couponAmount}</span>
                                {this.t('estimate.k018')}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            className={
                              car.checked
                                ? `${style.cbx} ${style.checked}`
                                : style.cbx
                            }
                          >
                            <div
                              onClick={() => {
                                this.checkCar(item, car);
                              }}
                            ></div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {this.isNotRecommended ? (
              <div className={style.notRecommended}>
                <ExclamationTriangleOutline />{' '}
                {this.state.scene.recommendCarTypeTip ||
                  '确保安全的情况下，鼓励员工用车首选普通车型'}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Popup
          position="bottom"
          visible={this.state.showPhone}
          onMaskClick={() => {
            this.setState({
              showPhone: false,
            });
          }}
          bodyStyle={{ minHeight: '20%' }}
        >
          {/* 设置手机号 */}
          <div className={style.set_phone}>
            <div className={style.title}>{this.t('estimate.k027')}</div>
            <div className={style.phone}>
              <Input
                type="number"
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
                placeholder={this.t('estimate.k028')}
                value={this.state.inner_phone}
                clearable={true}
                onChange={(val) => {
                  this.setState({
                    inner_phone: val,
                  });
                }}
              />
            </div>
            <div>
              <Button
                block
                color="primary"
                size="large"
                onClick={() => {
                  if (!checkPhone(this.state.inner_phone)) {
                    Toast.show({
                      content: this.t('estimate.k029'),
                    });
                    return;
                  }
                  this.confirmStore.setPassengerPhone(
                    this.state.inner_phone.trim()
                  );
                  this.setState({
                    showPhone: false,
                  });
                }}
              >
                {this.t('estimate.k030')}
              </Button>
            </div>
          </div>
        </Popup>
        <Popup
          position="bottom"
          visible={this.state.showMode}
          getContainer={() =>
            document.getElementsByClassName('_3cqVo8JdfokTjybEhOtFiy')[0]
          }
          bodyClassName={style.popupMode}
          onMaskClick={() => {
            this.setState({
              showMode: false,
            });
          }}
          bodyStyle={{ minHeight: '20%' }}
        >
          {/* 设置派车模式 */}
          <DispatchMode
            t={this.props.t}
            onChange={(v) => {
              this.setState({
                showMode: false,
              });
            }}
            dispatchModeList={this.state.dispatchModeList}
          ></DispatchMode>
        </Popup>
        <Popup
          onMaskClick={() => {
            this.setState({
              showSmartCar: false,
            });
          }}
          position="bottom"
          visible={this.state.showSmartCar}
          getContainer={() =>
            document.getElementsByClassName('_3cqVo8JdfokTjybEhOtFiy')[0]
          }
          bodyClassName={style.popupMode}
          bodyStyle={{ minHeight: '30%' }}
        >
          <div className={style.smart_wrap}>
            <div className={style.smart_car}>
              {
                this.state.smartLabelCar.length > 0 ? <div className={style.select}>{this.t('estimate.k045', {count: this.state.smartLabelCar.length})}</div> : <div>{this.t('estimate.k046')}</div>
              }
              
              <div className={style.all}>
                <div className={style.title}>{this.t('estimate.k006')}</div>
                <div
                  className={
                    this.state.smartCheckAll
                      ? `${style.cbx} ${style.checked}`
                      : style.cbx
                  }
                >
                  <div onClick={() => this.checkSmartCarAll()}></div>
                </div>
              </div>
            </div>
            {/* 选择车型 */}
            <div className={style.smart_car_list}>
              {this.state.smartLabelCar.map((car, index) => {
                return (
                  <div className={style.car} key={index}>
                    <div>
                      <img src={car?.carSourceImg} alt="" />
                    </div>
                    <div>
                      <div>{car.carSource}</div>
                    </div>
                    <div>
                      <div>
                        {!car.isFixed ? this.t('estimate.k007') : this.t('estimate.k048')}
                        <span>{car.estimatePrice}</span>
                        {this.t('estimate.k018')}
                      </div>
                    </div>
                    <div
                      className={
                        car.checked ? `${style.cbx} ${style.checked}` : style.cbx
                      }
                    >
                      <div
                        onClick={() => {
                          this.checkSmartCar(car, index);
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.smart_car_btn}>
            <Button disabled={this.isDissatisfied} block style={
              {
                'background-image': 'var(--button_background_color)',
                '--border-color': 'var(--button_border_color)',
                'background-color': 'var(--button_border_color)',
                'color': 'var(--button_color)'
              }
            } size='middle' onClick={() => this.smartDetermine()}>{this.t('estimate.k047')}</Button>
            </div>
          </div>
        </Popup>
        <div
          className={this.paddingStyleTop ? style.btncont_top : style.btncont}
        >
          <div className={style.cell}>
            <div
              className={
                gc.process.skipConfirmationPage &&
                gc.process.estimatePageShowPhoneInput &&
                this.globalStore.userInfo.permissions.setting_phone
                  ? style.inner_phone
                  : style.inner_phone_none
              }
              onClick={debounce(() => {
                if (this.globalStore.userInfo.permissions.order_editPhone) {
                  this.setState({
                    showPhone: true,
                    inner_phone: this.confirmStore.passenger.phone,
                  });
                }
              }, 500)}
            >
              <span>
                {this.t('estimate.k031')}
                {this.confirmStore.passenger.phone} 　
              </span>
            </div>
            {this.estimateStore.carMode > 0 ? (
              <div
                className={style.selectType}
                onClick={() => {
                  this.setState({
                    showMode: true,
                  });
                }}
              >
                <Image
                  src={this.modeTypeInfo.img}
                  width={19}
                  height={19}
                  fit="cover"
                />{' '}
                <span>{this.modeTypeInfo.value}</span>
                <Image src={images.backbtn} width={7} height={12} fit="cover" />
              </div>
            ) : (
              <></>
            )}
          </div>
          {this.mainStore.serverType !== 20 ? (
            <div
              className={
                this.state.currenCheckedDetails.count > 0 && this.props.callCar
                  ? `${style.btn} ${style.active}`
                  : style.btn
              }
              onClick={debounce(() => this.call(), 500)}
            >
              {this.state.currenCheckedDetails.count > 0 ? (
                <>
                  <div>
                    {this.t('estimate.k008')}
                    <span>
                      {' '}
                      ({this.t('estimate.k007')}
                      <span>
                        {this.state.currenCheckedDetails.leftPrice ===
                        this.state.currenCheckedDetails.rightPrice
                          ? this.state.currenCheckedDetails.leftPrice
                          : `${this.state.currenCheckedDetails.leftPrice}-${this.state.currenCheckedDetails.rightPrice}`}
                      </span>
                      {this.t('estimate.k018')})
                    </span>
                  </div>
                  <div>
                    {/* 已选择<span>{this.state.currenCheckedDetails.count}</span>种车型{this.state.currenCheckedDetails.detail} */}
                    {this.t('estimate.k010')}&nbsp;
                    <span>{this.state.currenCheckedDetails.count}</span>&nbsp;
                    {this.t('estimate.k019')}
                    {this.state.currenCheckedDetails.detail}
                  </div>
                </>
              ) : (
                <>
                  <div>{this.t('estimate.k008')}</div>
                  <div data-i18n="[html]estimate.k010">
                    {/* 已选择<span>0</span>种车型 */}
                    {this.t('estimate.k010')}
                    <span>{this.t('estimate.k022')}</span>
                    {this.t('estimate.k019')}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              className={
                this.state.currenCheckedDetails.count > 0
                  ? `${style.btn_bus} ${style.active}`
                  : style.btn_bus
              }
              onClick={debounce(() => this.call(), 500)}
            >
              <div>{this.t('estimate.k032')}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  loadRules = (item) => {
    Modal.alert({
      title: (
        <div>
          <span className={style.rules}>{this.t('estimate.k033')}</span>111
        </div>
      ),
      content: (
        <div className={style.box}>
          <span className={style.title}>
            {this.state.priceRules.charteredBusType === 1
              ? this.t('estimate.k034')
              : this.t('estimate.k035')}
          </span>
          <table
            style={{
              width: '-webkit-fill-available',
              borderCollapse: 'collapse',
              textAlign: 'center',
              border: '#CCC 1px solid',
            }}
          >
            <tbody>
              <tr>
                {(() => {
                  if (
                    item.price &&
                    item.price.list &&
                    item.price.list.length > 0
                  ) {
                    return <th>{this.t('estimate.k036')}</th>;
                  } else {
                    return <th></th>;
                  }
                })()}
                <th>
                  {this.t('estimate.k037')}
                  <br />
                  {(() => {
                    if (
                      item.price &&
                      item.price.list &&
                      item.price.list.length > 0
                    ) {
                      return <span>{item.price.description}</span>;
                    } else {
                      return <span>{this.state.priceRules.description}</span>;
                    }
                  })()}
                </th>
                <th>{this.t('estimate.k038')}</th>
                <th>{this.t('estimate.k039')}</th>
              </tr>
              {item.price && item.price.list && item.price.list.length > 0
                ? item.price.list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.car}</td>
                        <td>{item.estimate}</td>
                        <td>{item.mileage}</td>
                        <td>{item.overtime}</td>
                      </tr>
                    );
                  })
                : this.state.priceRules &&
                  this.state.priceRules.list.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.car}</td>
                        <td>{item.estimate}</td>
                        <td>{item.mileage}</td>
                        <td>{item.overtime}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          <p className={style.msg}>{this.t('estimate.k040')}</p>
        </div>
      ),
      closeOnAction: false,
      confirmText: '',
      maskStyle: { minWidth: '306px' },
      getContainer: () =>
        document.getElementsByClassName('_3OhmmBlrryr8dBgGjpO9pK')[0],
      showCloseButton: true,
    });
  };

  async componentDidMount() {
    // 加载上次选择车型
    webService.order.getLastChooseCar().then((d) => {
      let { data } = d;
      if (data.code === 0) {
        if (data.data && data.data.list && data.data.list.length) {
          this.setState({
            lastChooseCar: data.data.list,
          });
        }
      }
    });

    if (gc.process.skipConfirmationPage) {
      let brforPassenger = this.mainStore.passenger;
      if (brforPassenger && brforPassenger.phone) {
        // 如果是用户主动取消订单后导航到第二部跳转而来,需考虑被取消订单使用的乘车人号码
        this.confirmStore.setPassenger({
          phone: brforPassenger.phone,
          name: brforPassenger.name,
        });
      } else {
        let d = await webService.basCity.getUserInfo();
        let { data } = d;
        if (data.code === 0) {
          this.confirmStore.setPassenger({
            phone: data.data.phone,
            name: data.data.nameCn,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    Dialog.clear();
  }

  init = (data) => {
    this.estimateStore.setEstimateId(data.data.estimateId);
    this.estimateStore.setCarMode(data.data.carMode);
    this.initDispatchMode(data.data.carMode);
    let activeLevel = 0;

    this.setState({
      lockLevel:
        data.data.scene.requiredLevel && this.mainStore.serverType !== 20
          ? data.data.scene.requiredLevel
          : [],
    });

    for(let i in data.data.carLabels) {
      let car = data.data.carLabels[i];
      car.sideBarCount = car.data.filter((a) => {
        return a.checkAll === true;
      }).length;
      for(let j in car.data) {
        let data = car.data[j];
        data.isShow = false;
        data.standbyPriceLab = data.priceLab;
        data.carNum = data.list.length;
        if (data.checkAll) {
          data.checked = true;
          data.checkCount = data.list.length
          for(let x in data.list) {
            let d = data.list[x]
            d.checked = true;
            d.isAddCar = true
          }
        } else {
          data.checkCount = 0;
          data.checked = false;
          for(let x in data.list) {
            let d = data.list[x]
            d.checked = false;
            d.isAddCar = false
          }
        }
      }
    }

    for (let i in data.data.cars) {
      let car = data.data.cars[i];
      car.checkCount = 0;
      car.checkAll = false;
    }
    if (
      this.estimateStore.choosedEstimates &&
      this.estimateStore.choosedEstimates.length
    ) {
      // 曾经选择过车型,需要再次勾选中
      for (let i in data.data.cars) {
        let car = data.data.cars[i];
        for (let j in car.list) {
          let es = car.list[j];
          if (this.isChooseCarByStore(es)) {
            es.checked = true;
          }
        }

        car.checkCount = car.list.filter((a) => {
          return a.checked === true;
        }).length;
        car.checkAll = car.checkCount === car.list.length;
      }
      let listEs = []


      // 曾经选择过标签车型, 需要再次勾选中
      for(let i in data.data.labels) {
        let car = data.data.labels[i];
        for(let j in car.data) {
          let dataCar = car.data[j]
          for(let x in dataCar.list) {
            let es = dataCar.list[x]
            if (this.isSmartChooseCarByStore(es)) {
              es.checked = true;
              es.isAddCar = true;
              listEs.push(es)
            }
          }

          dataCar.checkCount = dataCar.list.filter((a) => {
            return a.checked === true;
          }).length;

          let _listPrice = listEs.filter((a) => {
            return a.labelCode === dataCar.labelCode
          })

          let estimatePrice = ''
          if (_listPrice && _listPrice.length > 0) {
            let leftPrice = _listPrice[0].estimatePrice;
            let rightPrice = _listPrice && _listPrice.length > 1 ? _listPrice[_listPrice.length - 1].estimatePrice : '';
            if (leftPrice && rightPrice) {
              estimatePrice = leftPrice + '-' + rightPrice
            } else {
              estimatePrice = leftPrice
            }
            if (!dataCar.checkAll) {
              dataCar.priceLab = estimatePrice
            }
          }

          dataCar.checked = dataCar.checkCount > 0 ? true : false
          dataCar.checkAll = dataCar.checkCount === dataCar.list.length;
        }

        car.sideBarCount = car.data.filter((a) => {
          return a.checkCount > 0;
        }).length;
      }
    } else {
      let defaultLevel = data.data.scene.defaultLevel;
      if (this.mainStore.serverType === 20) {
        defaultLevel = [];
      }
      // 没有本地缓存,默认选择所有普通
      if (!(defaultLevel && defaultLevel.length)) {
        defaultLevel = [data.data.cars[0].id];
      }
      if (defaultLevel && defaultLevel.length) {
        for (let loop in defaultLevel) {
          let dl = defaultLevel[loop];
          let _default = data.data.cars.filter((a) => a.id === dl);
          if (_default && _default.length) {
            let car = _default[0];
            if (car && car.list && car.list.length) {
              let ptlist = car.list;
              for (let i in ptlist) {
                if (this.mainStore.serverType !== 20) {
                  ptlist[i].checked = true;
                }
              }
              car.checkCount = car.list.filter((a) => {
                return a.checked === true;
              }).length;
              car.checkAll = car.checkCount === car.list.length;
            }
          }
        }
      }
      let allLevel = data.data.cars.map((a) => a.id);
      let maxDefaultLevel = allLevel.filter((a) => {
        return defaultLevel.includes(a);
      });
      if (maxDefaultLevel && maxDefaultLevel.length) {
        // 20230822修改为默认激活最低车型
        //activeLevel = maxDefaultLevel[maxDefaultLevel.length - 1]
        activeLevel = maxDefaultLevel[0];
      }
    }
    this.setState({
      activeLevel: data.data.carLabels[0]?.dynamicCode || activeLevel || data.data.cars[0].id,
      scene: data.data.scene,
      cars: data.data.cars,
      priceRules: data.data.price,
      carLabels: data.data.carLabels
    });

    setTimeout(() => {
      this.fillCurrenCheckedDetails();
    }, 200);
  };

  initDispatchMode = (value) => {
    let dispatchModeList = this.props.dispatchModeList;
    for (let i in dispatchModeList) {
      dispatchModeList[i].checked = false;
      if (value === dispatchModeList[i].value) {
        dispatchModeList[i].checked = true;
      }
    }
    this.setState({
      dispatchModeList: dispatchModeList,
    });
  };

  /**
   * 推荐标签车型弹出
   */
  showCarSmart = (item, index) => {
    this.setState({
      labelCode: item.labelCode
    })
    let list = this.state.carLabels;
    for (let i in list) {
      for (let j in list[i].data) {
        let row = list[i].data[j];
        row.list.forEach(l => {
          if(l.isAddCar) {
            l.checked = true;
            l.isAddCar = true;
          } else {
            l.checked = false;
            l.isAddCar = false;
          }
        })
        if (row.id === item.id) {
          row.isShow = true;
          this.setState({
            showSmartCar: true,
            smartLabelCar: item.list,
            smartCheckAll: row.list.filter(item => !item.checked).length === 0,
          });
        } else {
          row.isShow = false;
        }
      }
    }
  };

  /**
   * 勾选前次下单车型
   */
  chooseLastCar = () => {
    let { cars } = this.state;
    if (cars.length === 0) {
      return;
    }
    for (let i in cars) {
      let car = cars[i];
      for (let j in car.list) {
        let es = car.list[j];
        es.checked = this.isChooseCarByLastCars(es);
      }
      car.checkCount = car.list.filter((a) => {
        return a.checked === true;
      }).length;
      car.checkAll = car.checkCount === car.list.length;
    }

    this.setState({
      cars: cars,
    });

    setTimeout(() => {
      this.fillCurrenCheckedDetails();
    }, 200);
  };

  /**
   * 默认选择普通
   */
  choosedefaultCarLevel = () => {
    let { cars } = this.state;

    for (let i in cars) {
      let car = cars[i];
      for (let j in car.list) {
        let es = car.list[j];
        es.checked = this.isChooseCarByLastCars(es);
      }
      car.checkCount = car.list.filter((a) => {
        return a.checked === true;
      }).length;
      car.checkAll = car.checkCount === car.list.length;
    }

    this.setState({
      cars: cars,
    });

    setTimeout(() => {
      this.fillCurrenCheckedDetails();
    }, 200);
  };

  /**
   * 判断给定车型是否存在于前次下单选择车型列表
   * @param {*} item
   * @returns
   */
  isChooseCarByLastCars = (item) => {
    let { lastChooseCar } = this.state;

    for (let i in lastChooseCar) {
      let c = lastChooseCar[i];
      if (c.carLevel === item.carLevel && c.carSourceId === item.carSourceId) {
        return true;
      }
    }
    return false;
  };

  /**
   * 判断给定车型是否存在于已选择车型列表
   * @param {*} item
   * @returns
   */
  isChooseCarByStore = (item) => {
    for (let i in this.estimateStore.choosedEstimates) {
      let c = this.estimateStore.choosedEstimates[i];
      if (
        c.carLevel === item.carLevel &&
        c.carSourceId === item.carSourceId &&
        c.carSource === item.carSource && !c.labelCode
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * 判断给定标签车型是否存在于已选择车型列表
   * @param {*} item
   * @returns
   */
  isSmartChooseCarByStore = (item) => {
    for (let i in this.estimateStore.choosedEstimates) {
      let c = this.estimateStore.choosedEstimates[i];
      if (
        c.carLevel === item.carLevel &&
        c.carSourceId === item.carSourceId &&
        c.carSource === item.carSource &&
        c.labelCode === item.labelCode
      ) {
        return true;
      }
    }
    return false;
  }

  /**
   * 全选某个车等级
   * @param {*} carlevel
   */
  checkLevelAll = (carlevel) => {
    if (this.state.lockLevel.includes(carlevel.id)) {
      Toast.show({
        content: this.t('estimate.k041'),
      });
      return;
    }
    carlevel.checkAll = !carlevel.checkAll;
    if (carlevel.list.length) {
      carlevel.list.forEach((a) => {
        a.checked = carlevel.checkAll;
      });
      carlevel.checkCount = carlevel.list.filter((a) => {
        return a.checked === true;
      }).length;
    }

    this.setState({
      activeLevel: carlevel.id,
    });

    this.estimateStore.setCheckedCars(this.state.cars);

    this.fillCurrenCheckedDetails();
  };

  /**
   * 选择某个运力平台
   * @param {*} carlevel
   * @param {*} car
   */
  checkCar = (carlevel, car) => {
    if (this.state.lockLevel.includes(carlevel.id)) {
      Toast.show({
        content: this.t('estimate.k041'),
      });
      return;
    }
    if (this.mainStore.serverType === 20) {
      this.state.cars.forEach((a) => {
        if (a.checkCount > 0) {
          a.list.forEach((b) => {
            a.checkCount = 0;
            if (b.carLevel !== 10) {
              b.checked = !b.checked;
            } else {
              if (this.state.activeLevel !== 5) {
                b.checked = false;
              }
            }
          });
        }
      });
    }
    car.checked = !car.checked;
    carlevel.checkCount = carlevel.list.filter((a) => {
      return a.checked === true;
    }).length;
    carlevel.checkAll = carlevel.checkCount === carlevel.list.length;
    this.setState({
      activeLevel: carlevel.id,
    });
    this.fillCurrenCheckedDetails();
  };

  /**
   * 选择标签下某个资源
   * @param {*} carlevel
   * @param {*} car
   */
  checkSmartCar = (car, index) => {
    car.checked = !car.checked;
    this.setState({
      activeLevel: this.state.activeLevel,
      smartCheckAll: this.state.smartLabelCar.filter(item => !item.checked).length === 0
    });
  }

  /**
   * 全选标签平台下资源
   * @param {*} carlevel
   * @param {*} car
   */
  checkSmartCarAll = () => {
    this.setState({
      smartCheckAll: !this.state.smartCheckAll
    }, () => {
      for(let i in this.state.smartLabelCar) {
        let car = this.state.smartLabelCar[i];
        car.checked = this.state.smartCheckAll
      }
      this.setState({
        activeLevel: this.state.activeLevel,
      });
    });
  }

  /**
   * 全选标签下资源
   * @param {*} carlevel
   * @param {*} car
   */
  checkSmartLevelAll = (data, e) => {
    e.priceLab = e.standbyPriceLab
    data.data.forEach(item => {
      if (item.id === e.id) {
        item.checked = !item.checked;
        item.checkAll = item.checked

        item.list.forEach((a) => {
          a.checked = item.checkAll;
          a.isAddCar = item.checkAll
        })
        item.checkCount = item.list.filter((a) => {
          return a.checked === true;
        }).length;
      }
      
    })
    data.sideBarCount = data.data.filter((a) => {
      return a.checkCount > 0;
    }).length;

    this.setState({
      activeLevel: data.dynamicCode,
    });
    
    this.fillCurrenCheckedDetails()
  }

  /**
   * 选择确定推荐标签下资源
   */
  smartDetermine = () => {
    let _list = this.state.smartLabelCar.filter((a) => {
      return a.checked === true
    });

    _list = _list.sort((a, b) => {
      return a.estimatePrice - b.estimatePrice;
    })

    let estimatePrice = ''
    if (_list && _list.length > 0) {
      let leftPrice = _list[0].estimatePrice;
      let rightPrice = _list && _list.length > 1 ? _list[_list.length - 1].estimatePrice : '';
      if (leftPrice && rightPrice) {
        estimatePrice = leftPrice + '-' + rightPrice
      } else {
        estimatePrice = leftPrice
      }
      this.state.smartLabelCar.forEach(o => {
        _list.forEach(b => {
          if (o.subCarType === b.subCarType) {
            o.isAddCar = true
          } else {
            o.isAddCar = false
          }
        })
      })
          
      _list.forEach(b => {
        b.isAddCar = true
      })

    } else {
      this.state.smartLabelCar.forEach(o => {
        o.checked = false
        o.isAddCar = false
      })
    }

    for(let i in this.state.carLabels) {
      let car = this.state.carLabels[i];
      for(let j in car.data) {
        let data = car.data[j];
        data.checked =  data.list.some(item => item.checked);
        data.checkAll = data.checkCount === data.list.length;
        if (_list && _list.length > 0 && _list[0].labelCode === data.labelCode) {
          data.priceLab = estimatePrice
          this.setState({
            labelCode: data.labelCode,
            activeLevel: car.dynamicCode
          })
        } else {
          if (this.state.labelCode === data.labelCode) {
            data.priceLab = data.standbyPriceLab
          }
        }
        data.checkCount = data.list.filter((a) => {
          return a.checked === true;
        }).length;
      }
      car.sideBarCount = car.data.filter((a) => {
        return a.checkCount > 0;
      }).length;
    }
    
    this.setState({
      showSmartCar: false
    })

    this.fillCurrenCheckedDetails()
  }

  /**
   * 填充选中运力平台数据
   */
  fillCurrenCheckedDetails = () => {
    let currenCheckedDetails = this.state.currenCheckedDetails;
    currenCheckedDetails.count = 0;
    currenCheckedDetails.detail = '';
    let _list = [];
    let _listChecked = [];
    let recommendCarChecked = [];
    
    let carLabels = this.state.carLabels;

    for(let i in carLabels) {
      let list =  carLabels[i].data
      for(let j in list) {
        if (list[j].checkCount > 0) {
          _list.push(`${list[j].checkCount}个${list[j].labelName}`)
        }
        currenCheckedDetails.count += list[j].checkCount
        for(let x in list[j].list) {
          if (list[j].list[x].checked) {
            recommendCarChecked.push(list[j].list[x])
          }
        }
      }
    }

    this.state.cars.forEach((a) => {
      currenCheckedDetails.count += a.checkCount;
      if (a.checkCount > 0) {
        _list.push(`${a.checkCount}个${a.nameCn}`);

        a.list.forEach((b) => {
          if (b.checked) {
            _listChecked.push(b);
          }
        });
      }
    });
    
    if (currenCheckedDetails.count > 0) {
      currenCheckedDetails.detail = `（${_list.toString().replace(/,/g, '+')}）`;

      _listChecked = _listChecked.concat(recommendCarChecked)

      _listChecked = _listChecked.sort((a, b) => {
        return a.estimatePrice - b.estimatePrice;
      });


      currenCheckedDetails.list = _listChecked;
      currenCheckedDetails.leftPrice = _listChecked[0].estimatePrice;
      currenCheckedDetails.rightPrice =
        _listChecked[_listChecked.length - 1].estimatePrice;
    }

    this.setState({
      currenCheckedDetails: currenCheckedDetails,
    });
  };

  /**
   * 跳转到下一步
   */
  call = async () => {
    let { currenCheckedDetails } = this.state;
    if (currenCheckedDetails.count < 1 || !this.props.callCar) {
      // 還未選擇車型
      return;
    }
    let choosedEstimates = currenCheckedDetails.list;

    // 判断能否进行下一步骤
    let cep = generateConfirmDependency(
      this.mainStore.serverType,
      this.mainStore.scene,
      this.mainStore.beginAddr,
      this.mainStore.endAddr,
      choosedEstimates,
      this.mainStore.preApply,
      this.mainStore.charteredBusType,
      this.mainStore.pathways
    );
    try {
      let res = await webService.order.preCheck2(cep);
      if (res.data.code !== 0) {
        if (res.data.data && res.data.data.forwardUrl) {
          Dialog.show({
            title: this.t('common.k004'),
            content: res.data.data.fprwardBtnText,
            actions: [
              [
                {
                  key: 'go_set',
                  text: (
                    <div className={style.btn_details}>
                      {this.t('common.k006')}
                    </div>
                  ),
                  bold: true,
                  onClick: () => {
                    this.showMessageBox(res.data.data.forwardUrl);
                  },
                },
                {
                  key: 'cancel',
                  text: (
                    <div className={style.btn_close}>
                      {this.t('common.k005')}
                    </div>
                  ),
                  onClick: () => {
                    this.props.history.go(-1);
                  },
                },
              ],
            ],
            closeOnAction: true,
          });
        } else {
          Dialog.alert({
            content: res.data.message,
            confirmText: this.t('common.k003'),
            onConfirm: async () => {
              // 重选车型
              if (
                res.data.message.indexOf('个人剩余额度不足，无法发起叫车。') !==
                -1
              ) {
                this.props.history.goBack();
              } else if (res.data.code === 6007) {
                this.props.history.goBack();
              }
            },
          });
        }
        return;
      }
    } catch (exp) {
      Dialog.alert({
        content: this.t('estimate.k011'),
        confirmText: this.t('common.k003'),
      });
      return;
    }

    this.estimateStore.setChoosedEstimates(choosedEstimates);
    this.estimateStore.setCheckedCars(this.state.cars);
    // this.confirmStore.clear();
    if (gc.process.skipConfirmationPage) {
      // 跳过确认用车页面
      this.perPlaceOrder();
    } else {
      this.props.history.push('/confirm');
    }
  };

  /**
   * 确认叫车前参数准备
   */
  perPlaceOrder = async () => {
    // 设置确认页必要参数
    if (!this.confirmStore.passenger.phone) {
      let brforPassenger = this.mainStore.passenger;
      if (brforPassenger && brforPassenger.phone) {
        // 如果是用户主动取消订单后导航到第二部跳转而来,需考虑被取消订单使用的乘车人号码
        this.confirmStore.setPassenger({
          phone: brforPassenger.phone,
          name: brforPassenger.name,
        });
      } else {
        let d = await webService.basCity.getUserInfo();
        let { data } = d;
        if (data.code === 0) {
          this.confirmStore.setPassenger({
            phone: data.data.phone,
            name: data.data.nameCn,
          });
        }
      }
    }

    let cep = generateConfirmDependency(
      this.mainStore.serverType,
      this.mainStore.scene,
      this.mainStore.beginAddr,
      this.mainStore.endAddr,
      this.estimateStore.choosedEstimates,
      this.mainStore.preApply,
      this.mainStore.charteredBusType,
      this.mainStore.pathways
    );
    let { data } = await webService.order.getConfirmDependency(cep);
    if (data.code === 0) {
      data.data.existence = true;
      this.confirmStore.setDependency(data.data);
    }

    this.placeOrder();
  };

  /**
   * 确认叫车
   */
  placeOrder = async () => {
    let { serverType, scene, beginAddr, endAddr } = this.mainStore;
    let { choosedEstimates } = this.estimateStore;
    let { passenger, project, coupon, dependency, account } = this.confirmStore;

    let cacheOrderParams = {
      mainStore: {
        mapCenter: this.mainStore.mapCenter,
        serverType: this.mainStore.serverType,
        scene: this.mainStore.scene,
        preApply: this.mainStore.preApply,
        beginAddr: this.mainStore.beginAddr,
        pathways: this.mainStore.pathways,
        endAddr: this.mainStore.endAddr,
        baseLimitationConfirm: this.mainStore.baseLimitationConfirm,
      },
      estimateStore: {
        choosedEstimateCarLevel: this.estimateStore.choosedEstimateCarLevel,
        choosedEstimates: this.estimateStore.choosedEstimates.map((a) => {
          // return {
          //   carSource: a.carSource,
          //   carSourceId: a.carSourceId,
          //   carLevel: a.carLevel
          // }
          return [a.carSource, a.carSourceId, a.carLevel, a.labelCode, a.subCarType].join(',');
        }),
      },
      confirmStore: {
        passenger: this.confirmStore.passenger,
      },
    };
    let order_params = stringToBase64(JSON.stringify(cacheOrderParams));

    let cep = generatePlaceOrder(
      serverType,
      scene,
      beginAddr,
      endAddr,
      choosedEstimates,
      passenger,
      project,
      coupon,
      dependency.extraServices,
      dependency.scene,
      this.estimateStore.estimateId,
      account.id,
      this.confirmStore.customCarList,
      this.mainStore.preApply,
      dependency.checkLowCarbon,
      this.mainStore.charteredBusType,
      this.estimateStore.carMode,
      this.mainStore.pathways,
      this.mainStore.baseLimitationConfirm
    );
    cep.orderParams = order_params;

    Toast.show({
      icon: 'loading',
      content: this.t('confirm.k029'),
      duration: 0,
      maskClickable: false,
    });

    loger.info('预估页面', '跳过确认用车页面', cep);

    try {
      if (cep.destLat && cep.destLng && cep.departLat && cep.departLng) {
        let { data } = await webService.order.placeOrder(cep);
        this.placeOrderResp(data, cep);
      }
    } catch (exp) {
      console.log('exp', exp);
    } finally {
      Toast.clear();
    }
  };

  placeOrderResp = async (data, cep) => {
    // 需要预付费
    if (data.code === 0 && data.data.prePayFlag) {
      localStorage.setItem('transaction_no', '');
      localStorage.setItem('prepaidInfo', JSON.stringify(data.data));
      this.props.history.push('/prepaid');
    } else if (data.code === 0 && data.data.orderId) {
      let isAirportPickup =
        this.confirmStore.isCheckedExtraServicesForCode(ES_JieJiLiBin);

      // 下单成功
      this.mainStore.clear();
      this.estimateStore.clear();
      this.confirmStore.clear();

      if (isAirportPickup) {
        // 选择了接机礼宾服务,跳转到等待神州接单页面
        this.props.history.push('/waitingShenzhou/' + data.data.orderId);
      } else {
        // 跳转到等待接单页面
        this.props.history.replace('/runing/' + data.data.orderId);
      }
    } else if (data.code === 20001) {
      Dialog.confirm({
        content: data.message,
        cancelText: this.t('common.k001'),
        confirmText: this.t('common.k002'),
        onConfirm: async () => {
          cep.confirm = true;
          let { data } = await webService.order.placeOrder(cep);
          this.placeOrderResp(data, cep);
        },
      });
    } else if (data.code === 20002 || data.message.indexOf('20002') !== -1) {
      Dialog.alert({
        content: this.t('confirm.k030'),
        confirmText: this.t('common.k003'),
        onConfirm: async () => {
          // 重选车型
        },
      });
    } else {
      if (data.data && data.data.forwardUrl) {
        Dialog.show({
          title: this.t('common.k004'),
          content: data.data.fprwardBtnText,
          actions: [
            [
              {
                key: 'go_set',
                text: (
                  <div className={style.btn_details}>
                    {this.t('common.k006')}
                  </div>
                ),
                bold: true,
                onClick: () => {
                  this.showMessageBox(data.data.forwardUrl);
                },
              },
              {
                key: 'cancel',
                text: (
                  <div className={style.btn_close}>{this.t('common.k005')}</div>
                ),
                onClick: () => {
                  this.props.history.go(-1);
                },
              },
            ],
          ],
          closeOnAction: true,
        });
      } else {
        Dialog.confirm({
          content: data.message,
          cancelText: this.t('common.k001'),
          confirmText: this.t('common.k002'),
          onConfirm: async () => {
            // 重选车型
          },
        });
      }
    }
  };

  get isNotRecommended() {
    if (
      this.state.scene.recommendCarType &&
      this.state.scene.recommendCarType.length
    ) {
      let recommendCarType = this.state.scene.recommendCarType;
      let choosedEstimates = this.state.cars
        .filter((a) => a.checkCount > 0)
        .map((a) => a.id);

      loger.debug(recommendCarType, choosedEstimates);

      for (let i in choosedEstimates) {
        if (!recommendCarType.includes(choosedEstimates[i])) {
          return true;
        }
      }
    }

    return false;
  }

  // 显示错误提示信息
  showMessageBox(url) {
    Dialog.clear();
    this.messageBoxRef.show(url);
    let fr = new ForgeRouter(() => {
      this.messageBoxRef.hide('messageBoxRef');
    });
    fr.onpopstate();
  }

  handleScroll = () => {
    if (this.state.isScrolling) {
      return true
    }
    const scrollTop = this.contentRef.scrollTop;
    const scrollHeight = this.contentRef.scrollHeight;
    const clientHeight = this.contentRef.clientHeight;

    this.smartRefs.forEach(item => {
      let offsetTop = item.dom.offsetTop;
      let offsetHeight = item.dom.offsetHeight;
      let key = item.id;
      // 检查是否滚动到顶部
      if (scrollTop === 0) {
        this.setState({ activeLevel: key });
        return;
      }

      // 检测当前滚动位置是否在该section范围内
      if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
        this.setState({ activeLevel: key });
       }
    })

    this.sectionRefs.forEach((item, index) => {
      let offsetTop = item.dom.offsetTop;
      let offsetHeight = item.dom.offsetHeight;
      let key = item.id;

       // 检查是否滚动到顶部
      if (scrollTop === 0 && this.smartRefs.length === 0) {
        this.setState({ activeLevel: this.sectionRefs[0].id });
        return;
      }

      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.setState({ activeLevel: this.sectionRefs[this.sectionRefs.length - 1].id });
        return;
      }
       // 检测当前滚动位置是否在该section范围内
       if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
        this.setState({ activeLevel: key });
       }
    })
  }
}

export default SmartCarTypeChoose;
