import React from 'react'
import style from './manualWaitingOrders.scss';

import PropTypes from 'prop-types'

import MakeAppointment from './makeAppointment'

import { inject, observer } from 'mobx-react'
import { Button, Popup } from 'antd-mobile'
import { withRouter } from "react-router-dom"

import AppendManualChoose from './appendManualChoose';
@withRouter
@inject('runingOrderStore', 'globalStore')
@observer
class ManualWaitingOrders extends React.Component {
  state = {
    showAppendCars: false
  };
  runingOrderStore = this.props.runingOrderStore;
  globalStore = this.props.globalStore;

  t = this.props.t

  render() {
    let cars = this.runingOrderStore.cars;
    return <>
      {
        (() => {
          if (this.props.isBookingOrder && this.props.serviceType === 2) {
            return (
              <MakeAppointment cancelOrder={this.props.cancelOrder} departTime={this.props.departTime} t={this.props.t} history={this.props.history} />
            )
          } else {
            return (
              <div className={style.summary}>
                <div>
                  <div>
                    {this.t('runing.k016', { carLength: this.runingOrderStore.carLength })}
                  </div>
                </div>
                <div>
                  <Button color='primary' fill='outline' onClick={() => { this.props.cancelOrder(); }}>{this.t('runing.k007')}</Button>
                </div>
              </div>
            )
          }
        })()
      }

      <div className={this.props.serviceType === 1 ? style.manualOrderDetail : style.manualOrderDetailHousekeeper}>
        <div className={style.cars}>
          {
            cars.map(item => {
              return <div className={style.carpannel} key={item.id}>
                <div className={style.head}>
                  <div>{item.nameCn}{this.t('runing.k017')}</div>
                </div>

                <div className={style.carlist}>
                  {
                    item.list.map((car, index) => {
                      return <div className={style.car} key={car.dynamicCode}>
                        <div>
                          <img src={car.carSourceImg} alt="" />
                        </div>
                        <div>{car.carSource}</div>
                        <div>
                          <div>{!car.isFixed ? this.t('estimate.k007') : this.t('runing.k018')}<span>{car.estimatePrice}</span>{this.t('runing.k070')}</div>
                          {
                            car.couponAmount
                              ? <div>{this.t('estimate.k021')}<span>{car.couponAmount}</span>{this.t('estimate.k018')}</div>
                              : <></>
                          }
                        </div>
                      </div>
                    })
                  }


                </div>


              </div>
            })
          }
        </div>
      </div>
      {
        (() => {
          if (this.globalStore.userInfo.permissions && this.globalStore.userInfo.permissions.ongoing_addcar) {
            return (
              <div className={style.toolBar}>

                <Button block style={
                  {
                    'background-image': 'var(--button_background_color)',
                    '--border-color': 'var(--button_border_color)',
                    'background-color': 'var(--button_border_color)',
                    'color': 'var(--button_color)'
                  }
                } size='large' onClick={() => {
                  this.setState({ showAppendCars: true })
                  this.props.clearTimer()
                }}>
                  {this.t('runing.k019')}
                </Button>
                <Popup
                  visible={this.state.showAppendCars}
                  onMaskClick={() => {
                    this.setState({
                      showAppendCars: false
                    })
                  }}
                  bodyStyle={{ height: '90vh' }}
                >
                  {
                    this.state.showAppendCars
                      ? (<AppendManualChoose t={this.props.t} history={this.props.history} orderId={this.props.orderId} changeAppend={this.changeAppend} />)
                      : (<></>)
                  }

                </Popup>
              </div>
            )
          }
        })()
      }

    </>
  }

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  changeAppend = () => {
    this.setState({
      showAppendCars: false
    })

    this.props.loadOrderBaseInfo();
  }

  handleShowPop = () => {
    this.setState({
      showAppendCars: true
    })
  }
}

ManualWaitingOrders.propTypes = {
  serviceType: PropTypes.number
}

ManualWaitingOrders.defaultProps = {
  serviceType: 1
}

export default ManualWaitingOrders;
