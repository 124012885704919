import React from 'react';
import style from './smartAppendManualChoose.scss';
import { inject, observer } from 'mobx-react';
import { Dialog, DotLoading, Popup, Button } from 'antd-mobile';
import { RightOutline } from 'antd-mobile-icons';

import webService from './../../util/webService';

import { debounce } from './../../util/utils';

import { findLogoSrc } from './../../util/logoCache'

import LoadingMask from './../../component/utils/loadingMask';

@inject('globalStore', 'estimateStore', 'runingOrderStore')
@observer
class SmartAppendManualChoose extends React.Component {
  state = {
    activeLevel: 1,
    lockLevel: [],
    cars: [],
    smartLabelCar: [],
    smartCheckAll: false,
    isCars: false,
    isShow: false,
    labelCode: '',
    currenCheckedDetails: {
      count: 0,
      detail: '',
      leftPrice: 0,
      rightPrice: 0,
      list: [],
    },
    carLabels: [],
    isScrolling: false
  };
  sectionRefs = [];
  smartRefs = [];
  contentRef = null
  globalStore = this.props.globalStore;
  estimateStore = this.props.estimateStore;
  runingOrderStore = this.props.runingOrderStore;

  t = this.props.t;

  dispatchModeRef = undefined;

  get isLanguage() {
    return this.globalStore.userInfo.language === 1 ? true : false;
  }

  render() {
    return (
      <div className={style.container}>
        <div className={style.car_box}>
          <div className={style.carlevel}>
            {/* 标签左侧导航 */}
            {this.state.carLabels.map((item, index) => {
              return (
                <div
                  className={
                    this.state.activeLevel === item.dynamicCode
                      ? `${
                          this.props.language === 1
                            ? style.level
                            : style.level_en
                        } ${style.active}`
                      : this.props.language === 1
                      ? style.level
                      : style.level_en
                  }
                  key={index}
                  onClick={() => {
                    this.setState({ activeLevel: item.dynamicCode, isScrolling: true });
                    // 延时后重置标志并更新状态
                    setTimeout(() => {
                      this.setState({isScrolling: false})
                    }, 800); // 根据实际情况调整延时
                    const element = this.smartRefs[index].dom;
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  <div className={style.name}>
                    {this.isLanguage ? item.nameCn : item.nameEn}
                    {item.sideBarCount ? (
                      <span>{item.sideBarCount}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={style.underline}></div>
                </div>
              );
            })}
            {/* 车型左侧导航 */}
            {this.state.cars.map((item, index) => {
              return (
                <div
                  className={
                    this.state.activeLevel === item.id
                      ? `${
                          this.props.language === 1
                            ? style.level
                            : style.level_en
                        } ${style.active}`
                      : this.props.language === 1
                      ? style.level
                      : style.level_en
                  }
                  key={index}
                  onClick={() => {
                    this.setState({ activeLevel:  item.id, isScrolling: true });
                    // 延时后重置标志并更新状态
                    setTimeout(() => {
                      this.setState({isScrolling: false})
                    }, 800); // 根据实际情况调整延时
                    const element = this.sectionRefs[index].dom;
                    element.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                  }}
                >
                  <div className={style.name}>
                    {this.isLanguage ? item.nameCn : item.nameEn}
                    {item.checkCount > 0 ? (
                      <span>{item.checkCount}</span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={style.underline}></div>
                </div>
              );
            })}
            {
              this.state.isScrolling ? <div className={style.carlevel_mask}></div> : <></>
            }
          </div>
          {
            this.state.carLabels.length > 0 || this.state.cars.length > 0 ?

            <div className={style.cars} onScroll={this.handleScroll} ref={(ref) => (this.contentRef = ref)}>
              {/* 标签右侧预估 */}
              {this.state.carLabels.map((item, index) => {
                return (
                  <div
                    className={`${style.car_smart} ${
                      index === this.state.carLabels.length - 1
                        ? style.car_smart_border_bottom
                        : style.car_smart_padding_top
                    }`}
                    key={index}
                    ref={(dom) => {
                      this.smartRefs[index] = {dom: dom, id:item.dynamicCode};
                    }}
                  >
                    {item.data.map((e, i) => {
                      return (
                        <div
                          key={i}
                          className={`${style.car_type} ${
                            i === item.data.length - 1 ? '' : style.car_bottom
                          }`}
                        >
                          <div className={style.left}>
                            <span className={style.car_source}>
                              {e.labelName}
                            </span>
                            <span className={style.car_desc}>{e.labelDesc}</span>
                            <div className={style.car_select} onClick={() => this.showCarSmart(e, i)}>
                            {this.t('estimate.k043')}&nbsp;
                              <span className={style.car_count}>
                                {e.checkCount}/{e.carNum}
                              </span>
                              &nbsp;{this.t('estimate.k044')}
                              <RightOutline color="#9DA3AB" fontSize={12} />
                            </div>
                          </div>
                          <div className={style.right}>
                            <div className={style.car_estimate}>
                            {this.t('estimate.k007')}&nbsp;<span>{e.priceLab}</span>&nbsp;{this.t('estimate.k018')}
                            </div>
                            <div
                              onClick={() => {
                                this.checkSmartLevelAll(item, e);
                              }}
                              className={
                                e.checked
                                  ? `${style.cbx} ${style.checked}`
                                  : style.cbx
                              }
                            >
                              <div></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              {/* 车型右侧预估 */}
              {this.state.cars.map((item, index) => {
                return (
                  <div
                    className={style.carpannel}
                    key={item.id}
                    ref={(dom) => {
                      this.sectionRefs[index] = {dom: dom, id: item.id};
                    }}
                  >
                    <div className={style.head}>
                      <div>
                        {item.nameCn}
                        {this.t('estimate.k005')}
                        {item.deductionCn && item.deductionCn.length > 0 ? (
                          item.deductionCn.map((val) => {
                            return (
                              <span className={style.deduction_key}>
                                {val.key.txt}
                                <span
                                  className={style.deduction_value}
                                  style={{ color: val.value.color }}
                                >
                                  {val.value.txt}
                                </span>
                              </span>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <div>{this.t('estimate.k006')}</div>
                        <div
                          className={
                            item.checkAll
                              ? `${style.cbx} ${style.checked}`
                              : style.cbx
                          }
                        >
                          <div
                            onClick={() => {
                              this.checkLevelAll(item);
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    <div className={style.carlist}>
                      {item.list.map((car, i) => {
                        return (
                          <div className={style.car} key={i}>
                            <div>
                              <img src={car?.carSourceImg} alt="" />
                            </div>
                            <div>
                              <div>{car.carSource}</div>
                            </div>
                            <div>
                              <div>
                                {!car.isFixed ? this.t('estimate.k007') : this.t('estimate.k048')}
                                <span>{car.estimatePrice}</span>
                                {this.t('estimate.k018')}
                              </div>
                              {car.couponAmount ? (
                                <div>
                                  {this.t('estimate.k021')}
                                  <span>{car.couponAmount}</span>
                                  {this.t('estimate.k018')}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div
                              className={
                                car.checked
                                  ? `${style.cbx} ${style.checked}`
                                  : style.cbx
                              }
                            >
                              <div
                                onClick={() => {
                                  this.checkCar(item, car);
                                }}
                              ></div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div> : <div className={style.loading}>
                        {
                          this.state.isCars ? this.t('runing.k071') : <DotLoading style={{ fontSize: 20 }}/>
                        }
                     </div>
          }
        </div>
        <Popup
          onMaskClick={() => {
            this.setState({
              showSmartCar: false,
            });
          }}
          position="bottom"
          visible={this.state.showSmartCar}
          getContainer={() =>
            document.getElementsByClassName('_2ropcmo-xzBRMIwc5hML2v')[0]
          }
          bodyClassName={style.popupMode}
          bodyStyle={{ minHeight: '30%' }}
        >
          <div className={style.smart_wrap}>
            <div className={style.smart_car}>
              {
                this.state.smartLabelCar.length > 0 ? <div className={style.select}>{this.t('estimate.k045', {count: this.state.smartLabelCar.length})}</div> : <div>{this.t('estimate.k046')}</div>
              }
              
              <div className={style.all}>
                <div className={style.title}>{this.t('estimate.k006')}</div>
                <div
                  className={
                    this.state.smartCheckAll
                      ? `${style.cbx} ${style.checked}`
                      : style.cbx
                  }
                >
                  <div onClick={() => this.checkSmartCarAll()}></div>
                </div>
              </div>
            </div>
            {/* 选择车型 */}
            <div className={style.smart_car_list}>
              {this.state.smartLabelCar.map((car, index) => {
                return (
                  <div className={style.car} key={index}>
                    <div>
                      <img src={car?.carSourceImg} alt="" />
                    </div>
                    <div>
                      <div>{car.carSource}</div>
                    </div>
                    <div>
                      <div>
                        {!car.isFixed ? this.t('estimate.k007') : this.t('estimate.k048')}
                        <span>{car.estimatePrice}</span>
                        {this.t('estimate.k018')}
                      </div>
                    </div>
                    <div
                      className={
                        car.checked ? `${style.cbx} ${style.checked}` : style.cbx
                      }
                    >
                      <div
                        onClick={() => {
                          this.checkSmartCar(car, index);
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={style.smart_car_btn}>
            <Button disabled={this.isDissatisfied} block style={
              {
                'background-image': 'var(--button_background_color)',
                '--border-color': 'var(--button_border_color)',
                'background-color': 'var(--button_border_color)',
                'color': 'var(--button_color)'
              }
            } size='middle' onClick={() => this.smartDetermine()}>{this.t('estimate.k047')}</Button>
            </div>
          </div>
        </Popup>
        <div className={style.btncont}>
                <div className={this.state.currenCheckedDetails.count > 0 ? `${style.btn} ${style.active}` : style.btn} onClick={debounce(() => this.call(), 0)}>
                    {
                        (() => {
                            if(this.state.currenCheckedDetails.count > 0 && this.state.prepaidAmount === 0) {
                                return(
                                    <>
                                    <div>
                                        {this.t('runing.k021')}<span> ({this.t('runing.k018')}<span>
                                        {this.state.currenCheckedDetails.leftPrice === this.state.currenCheckedDetails.rightPrice
                                        ? this.state.currenCheckedDetails.leftPrice
                                        : `${this.state.currenCheckedDetails.leftPrice}-${this.state.currenCheckedDetails.rightPrice}`
                                        }
                                          </span>{this.t('runing.k070')})</span>
                                    </div>
                                    <div>
                                        {this.t('runing.k022')}&nbsp;<span>{this.state.currenCheckedDetails.count}</span>&nbsp;{this.t('runing.k072')}{this.state.currenCheckedDetails.detail}
                                    </div>
                                    </>
                                )
                            } else if (this.state.prepaidAmount > 0 && this.state.currenCheckedDetails.rightPrice > 0) {
                                return (
                                    <>
                                        <div>
                                            {this.t('runing.k021')}<span> ({this.t('runing.k018')}<span>
                                            {this.state.currenCheckedDetails.leftPrice === this.state.currenCheckedDetails.rightPrice
                                        ? this.state.currenCheckedDetails.leftPrice
                                        : `${this.state.currenCheckedDetails.leftPrice}-${this.state.currenCheckedDetails.rightPrice}`
                                        }
                                              </span>{this.t('runing.k070')})</span>
                                        </div>
                                        <div>
                                            {this.t('runing.k073', {prepaidAmount: this.state.prepaidAmount})}
                                        </div>
                                    </>
                                )
                            } else {
                                return(
                                    <>
                                        <div>{this.t('runing.k023')}</div>
                                        <div>{this.t('runing.k022')}<span> {this.state.currenCheckedDetails.count} </span>{this.t('runing.k072')}</div>
                                    </>
                                )
                            }
                        })()
                    }
                </div>
        </div>
        {
          this.state.isShow ?
          <LoadingMask opacityNum={0.4} loadingType={'spin'} show={this.state.isShow} isPopup={false}></LoadingMask> : <></>
        }
      </div>
    );
  }

  async componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    Dialog.clear();
  }

  init = async () => {
    let activeLevel = 0;
    let {data} = await webService.order.appendEstimate({orderId: this.props.orderId})
    if (data.code === 0) {
      // 判断是否全选某一个车型
      let checkedCars = this.estimateStore.checkedCars.filter((item) => {
        return item.checkAll === true;
      });
      
      for(let i in data.data.labels) {
        let car = data.data.labels[i];
        car.sideBarCount = car.data.filter((a) => {
          return a.checkAll === true;
        }).length;
        for(let j in car.data) {
          let data = car.data[j];
          data.isShow = false;
          data.standbyPriceLab = data.priceLab;
          data.carNum = data.list.length;
          for(let x in data.list) {
            let d = data.list[x]
            d.carSourceImg = findLogoSrc(d.carSourceId);
          }
          if (data.checkAll) {
            data.checked = true;
            data.checkCount = data.list.length
            for(let x in data.list) {
              let d = data.list[x]
              d.checked = true;
              d.isAddCar = true
            }
          } else {
            data.checkCount = 0;
            data.checked = false;
            for(let x in data.list) {
              let d = data.list[x]
              d.checked = false;
              d.isAddCar = false
            }
          }
        }
      }
      
      for (let i in data.data.cars) {
        let car = data.data.cars[i];
        car.list = this.trimCarSource(car.list)
        car.list.forEach((item) => {
          item.carSourceImg = findLogoSrc(item.carSourceId);
        });
        car.list.sort((a, b) => a.estimatePrice - b.estimatePrice);
        car.checkCount = 0;
        // 之前如果单选过某个车型,则增加车型时单选的车型默认全部选中
        for (let j in this.runingOrderStore.cars) {
          let runingCars = this.runingOrderStore.cars[j];
          // 选择后一个高级车型让前一个车型全选
          if (
            runingCars &&
            runingCars.reimModel &&
            car.reimModel.carLevel < runingCars.reimModel.carLevel
          ) {
            car.checkAll = true;
            car.list.forEach((a) => {
              a.checked = car.checkAll;
            });
            car.checkCount = car.list.filter((a) => {
              return a.checked === true;
            }).length;
          }
          // 报销比例时
          else if (
            runingCars &&
            runingCars.reimModel &&
            runingCars.reimModel.carLevel === car.reimModel.carLevel
          ) {
            car.checkAll = true;
            car.list.forEach((a) => {
              a.checked = car.checkAll;
            });
            car.checkCount = car.list.filter((a) => {
              return a.checked === true;
            }).length;
          } else {
            // 报销全部时判断使用 id 报销全部时没有返回 reimModel

            // 选择后一个高级车型让前一个车型全选
            if (runingCars && runingCars.id && car.id < runingCars.id) {
              car.checkAll = true;
              car.list.forEach((a) => {
                a.checked = car.checkAll;
              });
              car.checkCount = car.list.filter((a) => {
                return a.checked === true;
              }).length;
            }
            // 选择某级单个车型让这级全选
            else if (
              runingCars &&
              runingCars.id &&
              runingCars.id === car.id
            ) {
              car.checkAll = true;
              car.list.forEach((a) => {
                a.checked = car.checkAll;
              });
              car.checkCount = car.list.filter((a) => {
                return a.checked === true;
              }).length;
            }
          }
        }
        // 如果全选某一个车型则增加车行时全选下一个更高车型
        checkedCars &&
          checkedCars.forEach((item) => {
            // 报销比例时
            if (item && item.checkAll && item.reimModel) {
              if (car.reimModel.carLevel < item.reimModel.carLevel) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              } else if (
                item.reimModel.carLevel === 1 &&
                car.reimModel.carLevel === 2
              ) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              } else if (
                item.reimModel.carLevel === 2 &&
                car.reimModel.carLevel === 3
              ) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              } else if (
                item.reimModel.carLevel === 3 &&
                car.reimModel.carLevel === 4
              ) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              }
            }
            // 报销全部时
            else if (item && item.checkAll && item.id) {
              if (car.id < item.id) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              } else if (item.id === 1 && car.id === 2) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              } else if (item.id === 2 && car.id === 3) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              } else if (item.id === 3 && car.id === 4) {
                car.checkAll = true;
                car.list.forEach((a) => {
                  a.checked = car.checkAll;
                });
                car.checkCount = car.list.filter((a) => {
                  return a.checked === true;
                }).length;
              }
            }
          });
      }
      this.setState(
        {
          activeLevel: (data.data.labels && data.data.labels[0]?.dynamicCode) || activeLevel || data.data.cars[0]?.id,
          cars: data.data.cars || [],
          carLabels: data.data.labels || [],
          estimateId: data.data.estimateId,
          isCars: data.data.cars.length > 0 || data.data.labels.length > 0 ? false : true,
        },
        () => {
          setTimeout(() => {
            this.fillCurrenCheckedDetails();
          }, 200)
        }
      );
    } else {
      this.setState({
        isShow: false,
        isCars: true,
        cars: []
      })
    }
  };

  /**
   * 推荐标签车型弹出
   */
  showCarSmart = (item, index) => {
    this.setState({
      labelCode: item.labelCode,
    });
    let list = this.state.carLabels;
    for (let i in list) {
      for (let j in list[i].data) {
        let row = list[i].data[j];
        row.list.forEach(l => {
          if(l.isAddCar) {
            l.checked = true;
            l.isAddCar = true;
          } else {
            l.checked = false;
            l.isAddCar = false;
          }
        })
        if (row.id === item.id) {
          row.isShow = true;
          this.setState({
            showSmartCar: true,
            smartLabelCar: item.list,
            smartCheckAll:
              row.list.filter((item) => !item.checked).length === 0,
          });
        } else {
          row.isShow = false;
        }
      }
    }
  };

  /**
   * 全选某个车等级
   * @param {*} carlevel
   */
  checkLevelAll = (carlevel) => {
    carlevel.checkAll = !carlevel.checkAll;
    if (carlevel.list.length) {
      carlevel.list.forEach((a) => {
        a.checked = carlevel.checkAll;
      });
      carlevel.checkCount = carlevel.list.filter((a) => {return a.checked === true}).length;

    }

    this.setState({
      activeLevel: carlevel.id,
    });
    
    this.fillCurrenCheckedDetails();
};

  /**
   * 选择某个运力平台
   * @param {*} carlevel
   * @param {*} car
   */
  checkCar = (carlevel, car) => {
    car.checked = !car.checked;
    carlevel.checkCount = carlevel.list.filter((a) => {return a.checked === true}).length;
    carlevel.checkAll = carlevel.checkCount === carlevel.list.length;
    this.setState({
      activeLevel: carlevel.id,
    });
    this.fillCurrenCheckedDetails();
  };

  /**
   * 选择标签下某个资源
   * @param {*} carlevel
   * @param {*} car
   */
  checkSmartCar = (car, index) => {
    car.checked = !car.checked;
    this.setState({
      activeLevel: this.state.activeLevel,
      smartCheckAll:
        this.state.smartLabelCar.filter((item) => !item.checked).length === 0,
    });
  };

  /**
   * 全选标签平台下资源
   * @param {*} carlevel
   * @param {*} car
   */
  checkSmartCarAll = () => {
    this.setState(
      {
        smartCheckAll: !this.state.smartCheckAll,
      },
      () => {
        for (let i in this.state.smartLabelCar) {
          let car = this.state.smartLabelCar[i];
          car.checked = this.state.smartCheckAll;
        }
        this.setState({
          activeLevel: this.state.activeLevel,
        });
      }
    );
  };

  /**
   * 全选标签下资源
   * @param {*} carlevel
   * @param {*} car
   */
  checkSmartLevelAll = (data, e) => {
    e.priceLab = e.standbyPriceLab;
    data.data.forEach((item) => {
      if (item.id === e.id) {
        item.checked = !item.checked;
        item.checkAll = item.checked;

        item.list.forEach((a) => {
          a.checked = item.checkAll;
          a.isAddCar = item.checkAll
        });
        item.checkCount = item.list.filter((a) => {
          return a.checked === true;
        }).length;
      }
    });
    data.sideBarCount = data.data.filter((a) => {
      return a.checkCount > 0;
    }).length;

    this.setState({
      activeLevel: data.dynamicCode,
    });

    this.fillCurrenCheckedDetails();
  };

  /**
   * 选择确定推荐标签下资源
   */
  smartDetermine = () => {
    let _list = this.state.smartLabelCar.filter((a) => {
      return a.checked === true;
    });

    _list = _list.sort((a, b) => {
      return a.estimatePrice - b.estimatePrice;
    });

    let estimatePrice = '';
    if (_list && _list.length > 0) {
      let leftPrice = _list[0].estimatePrice;
      let rightPrice =
        _list && _list.length > 1 ? _list[_list.length - 1].estimatePrice : '';
      if (leftPrice && rightPrice) {
        estimatePrice = leftPrice + '-' + rightPrice;
      } else {
        estimatePrice = leftPrice;
      }
      this.state.smartLabelCar.forEach(o => {
        _list.forEach(b => {
          if (o.subCarType === b.subCarType) {
            o.isAddCar = true
          } else {
            o.isAddCar = false
          }
        })
      })
          
      _list.forEach(b => {
        b.isAddCar = true
      })
    } else {
      this.state.smartLabelCar.forEach(o => {
        o.checked = false
        o.isAddCar = false
      })
    }

    for (let i in this.state.carLabels) {
      let car = this.state.carLabels[i];
      for (let j in car.data) {
        let data = car.data[j];
        data.checked = data.list.some((item) => item.checked);
        data.checkAll = data.checkCount === data.list.length;
        if (
          _list &&
          _list.length > 0 &&
          _list[0].labelCode === data.labelCode
        ) {
          data.priceLab = estimatePrice;
          this.setState({
            labelCode: data.labelCode,
            activeLevel: car.dynamicCode,
          });
        } else {
          if (this.state.labelCode === data.labelCode) {
            data.priceLab = data.standbyPriceLab;
          }
        }
        data.checkCount = data.list.filter((a) => {
          return a.checked === true;
        }).length;
      }
      car.sideBarCount = car.data.filter((a) => {
        return a.checkCount > 0;
      }).length;
    }

    this.setState({
      showSmartCar: false,
    });

    this.fillCurrenCheckedDetails();
  };

  /**
   * 填充选中运力平台数据
   */
  fillCurrenCheckedDetails = async () => {
    let currenCheckedDetails = this.state.currenCheckedDetails;
    currenCheckedDetails.count = 0;
    currenCheckedDetails.detail = '';
    let _list = [];
    let _listChecked = [];
    let recommendCarChecked = [];
    let carLevel = null

    let carLabels = this.state.carLabels;

    for (let i in carLabels) {
      let list = carLabels[i].data;
      for (let j in list) {
        if (list[j].checkCount > 0) {
          _list.push(`${list[j].checkCount}个${list[j].labelName}`);
        }
        currenCheckedDetails.count += list[j].checkCount;
        for (let x in list[j].list) {
          if (list[j].list[x].checked) {
            recommendCarChecked.push(list[j].list[x]);
          }
        }
      }
    }

    this.state.cars.forEach((a) => {
      currenCheckedDetails.count += a.checkCount;
      if (a.checkCount > 0) {
        _list.push(`${a.checkCount}个${a.nameCn}`);

        a.list.forEach((b) => {
          if (b.checked) {
            _listChecked.push(b);
          }
        });
      }
    });

    if (currenCheckedDetails.count > 0) {
      currenCheckedDetails.detail = `（${_list.toString().replace(/,/g, '+')}）`;

      _listChecked = _listChecked.concat(recommendCarChecked);

      _listChecked = _listChecked.sort((a, b) => {
        return a.estimatePrice - b.estimatePrice;
      });

      currenCheckedDetails.list = _listChecked;
      currenCheckedDetails.leftPrice = _listChecked[0].estimatePrice;
      currenCheckedDetails.rightPrice =
        _listChecked[_listChecked.length - 1].estimatePrice;
      carLevel = currenCheckedDetails.list.filter(a => a.estimatePrice === currenCheckedDetails.rightPrice)[0].carLevel;
    } else {
      // 则未选择增加车型
      currenCheckedDetails.rightPrice = 0
    }

    this.setState({
      currenCheckedDetails: currenCheckedDetails,
    });

    if (this.state.currenCheckedDetails.count > 0) {
      let _cars = this.state.currenCheckedDetails.list.map((a) => {
        return {
            carLevel: a.carLevel,
            carSourceId: a.carSourceId,
            estimatePrice: a.estimatePrice,
            dynamicCode: a.dynamicCode,
            carLevelName: a.carLevelName,
            couponId: a.couponId,
            couponAmount: a.couponAmount
        }
      });
      if(this.globalStore.companyInfo?.prePayFlag?.value === '1') {
        let {data} = await webService.order.getPrePayAmount({estimateId: this.state.estimateId, orderId: this.props.orderId, amount: currenCheckedDetails.rightPrice, carLevel: carLevel, cars: _cars, isValidationAccount: this.state.isValidationAccount})
        if (data.code === 0) {
            if (data.data.amount > 0) {
              let prepaidInfo = {
                amount: data.data.amount,
                orderId: data.data.orderId,
                time: data.data.time
              }
              // 存储预支付返回金额、倒计时、等
              localStorage.setItem('prepaidInfo', JSON.stringify(prepaidInfo));
            }
  
            this.setState({
                prepaidAmount: data.data.amount
            })
        } else {
          this.setState({
            prepaidAmount: 0
          })
          Dialog.alert({
            content: data.message,
            confirmText: this.t('common.k003'),
          })
        }
      }
    }
  };

  /**
   * 跳转到下一步
   */
  call = async () => {
    this.setState({
      isValidationAccount: true,
      isShow: true
    })

    let _cars = this.state.currenCheckedDetails.list.map((a) => {
      return {
          carLevel: a.carLevel,
          carSourceId: a.carSourceId,
          estimatePrice: a.estimatePrice,
          dynamicCode: a.dynamicCode,
          carLevelName: a.carLevelName,
          couponId: a.couponId,
          couponAmount: a.couponAmount,
          labelCode: a.labelCode || '',
          subCarType: a.subCarType || ''
      }
    });

    if (_cars && _cars.length > 0) {
      if (this.globalStore.companyInfo?.prePayFlag?.value === '1') {
        // 开启预支付
        this.checkPrePayAmount(_cars)
      } else {
        this.appendPlaceOrder(_cars)
      }
    } else {
      this.setState({
        isShow: false
      })
      Dialog.alert({
        content: this.t('runing.k078'),
        confirmText: this.t('common.k003'),
      })
    }

  };

  checkPrePayAmount = async (_cars) => {
    let carLevel = this.state.currenCheckedDetails.list.filter(a => a.estimatePrice === this.state.currenCheckedDetails.rightPrice)[0].carLevel
    let d = await webService.order.getPrePayAmount({estimateId: this.state.estimateId, orderId: this.props.orderId, amount: this.state.currenCheckedDetails.rightPrice, carLevel: carLevel, cars: _cars, isValidationAccount: this.state.isValidationAccount})
    // 需要预支付
    if (d.data.code === 1) {
      this.setState({
        prepaidAmount: 0,
        isValidationAccount: false
      })
      this.setState({
        isShow: false
      })
      Dialog.alert({
        content: d.data.message,
        confirmText: this.t('common.k003'),
      })
    } else {
      if (this.state.prepaidAmount > 0) {
        this.setState({
          isShow: false
        })
        this.runingOrderStore.setCars(this.state.cars)
        this.estimateStore.setCheckedCars(this.state.cars)
        sessionStorage.setItem('appendCarTypePrePay', true);
        this.props.history.push('/prepaid')
        // 先把追加车型入参存储到 localStorage, 支付成功后进行追加下单
      } else {
          // 追加车型需要判断个人支付是否超过预支付金额如果没有直接追加，
          // 如果超出需要等个人支付后超出预支付的金额, 然后回到进行中页面后，查询超出预支付状态成功后回到订单行程中页面, 追加下单由后端调用接口
          this.appendPlaceOrder(_cars)
      }
    }
  }

  appendPlaceOrder = async (_cars) => {
    let {data} = await webService.order.appendPlaceOrder({
      cars: _cars,
      orderId: this.props.orderId,
      estimateId: this.state.estimateId
    })

    if (data.code === 0) {
      this.estimateStore.setCheckedCars(this.state.cars)
      this.props.changeAppend()
      this.setState({
        isShow: false
      })
    } else {
      this.setState({
        isShow: false
      })
        Dialog.alert({
            content: data.message,
            confirmText: this.t('common.k003'),
        })
    }
  }

  handleScroll = () => {
    if (this.state.isScrolling) {
      return true
    }

    const scrollTop = this.contentRef.scrollTop;
    const scrollHeight = this.contentRef.scrollHeight;
    const clientHeight = this.contentRef.clientHeight;

    this.smartRefs.forEach(item => {
      let offsetTop = item.dom.offsetTop;
      let offsetHeight = item.dom.offsetHeight;
      let key = item.id;
      // 检查是否滚动到顶部
      if (scrollTop === 0) {
        this.setState({ activeLevel: this.smartRefs[0].id });
        return;
      }

      // 检测当前滚动位置是否在该section范围内
      if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
        this.setState({ activeLevel: key });
       }
    })

    this.sectionRefs.forEach((item, index) => {
      let offsetTop = item.dom.offsetTop;
      let offsetHeight = item.dom.offsetHeight;
      let key = item.id;

      //  // 检查是否滚动到顶部
      if (scrollTop === 0 && this.smartRefs.length === 0) {
        this.setState({ activeLevel: this.sectionRefs[0].id });
        return;
      }

      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        this.setState({ activeLevel: this.sectionRefs[this.sectionRefs.length - 1].id });
        return;
      }
       // 检测当前滚动位置是否在该section范围内
       if (scrollTop >= offsetTop && scrollTop < offsetTop + offsetHeight) {
        this.setState({ activeLevel: key });
       }
    })
  }

  /**
     * 去除同名的运力，保留低价
     * @param {*} list 
     * @returns 
     */
  trimCarSource = (list) => {
    let llst = [];
    for (let i in list) {
        let c = list[i]
        let item = llst.filter(a => a.carSource === c.carSource)
        if (item && item.length) {
            // 存在，比价，低价留下，高价放弃
        } else {
            // 没有，放入
            llst.push(c)
        }
    }
    return llst
  }
}

export default SmartAppendManualChoose;
